const Snippets = {
  tags: function ({id}) {
    const iframe = `<!-- Google Tag Manager (noscript) -->
            <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
            <!-- End Google Tag Manager (noscript) -->`;

    const script = `/* Google Tag Manager */
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${id}');
            /* End Google Tag Manager */`;

    return {
      iframe,
      script
    }
  }
}

const TagManager = {
  gtm: function (args) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    }

    const script = () => {
      const Script = document.createElement('script');
      Script.innerHTML = snippets.script;
      return Script;
    }

    return {
      noScript,
      script
    }
  },
  initialize: function (gtmId) {
    const gtm = this.gtm({
      id: gtmId
    })
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
}

module.exports = TagManager;
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import ReactCardFlip from "react-card-flip";
import {MainFeedbackContext} from "../../LocationFeedback";
import Questionnaires from "../../Questionnaires";
import {stringToTitle} from "../../../../util/Util";
import uniqid from "uniqid";


const QuestionsCard = ({getServiceType, isBI}) => {
  const {review} = useContext(MainFeedbackContext);
  const [isFlipped, setIsFlipped] = useState(false);
  const {t} = useTranslation();
  const tr = t('review', {returnObjects: true});

  const handleCtaQuestions = () => {
    setIsFlipped(false);
  }

  const renderQuestions = () => {
    let item = [];
    review.questions.forEach(function (value) {
      let resp = value.response;
      if (value.has_followup) {
        if (value.followup_reason !== '') {
          resp += ' - ' + stringToTitle(value.followup_reason);
        }
        if (value.followup_text !== '') {
          resp += ' - ' + value.followup_text;
        }
      }
      item.push(
        <div>
          <div className="question">{value.question}</div>
          <div className="response">{resp}</div>
        </div>
      );
    });
    return item;
  }

  return (
    <React.Fragment>
      <div className="section-title">
        <h2>{tr.sections.questions}</h2>
        <EditIcon className="edit-icon" onClick={() => setIsFlipped(true)}/>
      </div>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div className={!isFlipped ? 'review-questions' : 'card-hidden'}>
          <ol>
            {renderQuestions().map((item) => {
              return (<li key={uniqid()} className="form-field">{item}</li>)
            })}
          </ol>
        </div>
        <div className={isFlipped ? '' : 'card-hidden'}>
          <Questionnaires getServiceType={getServiceType} isEdit={true}
              handleCta={handleCtaQuestions} isBI={isBI}
          />
        </div>
      </ReactCardFlip>
    </React.Fragment>
  );
}

export default QuestionsCard;
import {findAgentsSaga, updateCurrentLocationSaga} from "./Locations";
import {loadQuesitonsSaga} from "./Questions";
import {updateReview} from "./Feedback";

const sagas = {
  findAgentsSaga,
  updateCurrentLocationSaga,
  loadQuesitonsSaga,
  updateReview,
};

function registerWithMiddleware(middleware) {
  for (const name in sagas) {
    middleware.run(sagas[name]);
  }
}

export default registerWithMiddleware;

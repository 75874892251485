import React, {useMemo, useState} from "react";
import './Scale.scss';
import {Form, Label} from "semantic-ui-react";
import {parseFollowupConditions, parseFollowupReasons, renderFollowup} from "../FieldsUtils";
import _ from "lodash";

const Scale = ({settings, handleChange, order, index, followupValue, defaultValue = 0}) => {
  const _default = _.isEmpty(defaultValue) ? 0 : defaultValue;
  const [stars, setStars] = useState(_default);
  const [followup_checked, setFollowupChecked] = useState({radio: followupValue.radio, text: followupValue.text});
  const label = settings.get('question_text');
  const low = settings.get('question_options_scale_question_options_scale_low');
  const high = settings.get('question_options_scale_question_options_scale_high');
  const followup_conditions = useMemo(() => parseFollowupConditions(settings), [settings]);
  const followup_reasons = useMemo(() => parseFollowupReasons(settings), [settings]);

  const handleFollowupChange = (event, newValue, type) => {
    event.preventDefault();
    const _value = followup_checked;
    _value[type] = newValue;
    setFollowupChecked(_value);
    handleChange(index, stars, _value, type);
  }

  const handleClick = (e, {value}) => {
    e.preventDefault();
    setStars(value);
    handleChange(index, value, followup_checked);
  }

  return (
    <React.Fragment>
      <div className="questions-scale">
        <Label>{order}. {label}</Label>
        <div className="rating-stars">
          <Form.Input
            label={stars}
            min={0}
            max={10}
            name='duration'
            onChange={handleClick}
            step={1}
            type='range'
            value={stars}
          />
        </div>
        <div className="labels"><span className='to-left'>{low}</span><span
          className='to-right'>{high}</span></div>
        {renderFollowup(settings, stars, followup_conditions, followup_reasons, followup_checked.radio, followup_checked.text, order, handleFollowupChange)}
      </div>
    </React.Fragment>
  );
}

export default Scale;

import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import AppReducers from "../reducers";
import { logger } from "redux-logger";
import registerWithMiddleware from "../sagas";

let store;
let configData, hosts = null;

export function initialize() {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();
  const persistedState = loadFromLocalStorage();

  function loadFromLocalStorage() {
    try {
      return window.localStorage.getItem('store')
          ? JSON.parse(localStorage.getItem('store'))
          : {};
    } catch(e) {
      console.log(e);
      return {};
    }
  }

  const localStorageMiddleware = ({getState}) => {
    return (next) => (action) => {
      const result = next(action);
      localStorage.setItem('store', JSON.stringify(
          getState()
      ));
      return result;
    };
  };

  // create store
  if (getConfigData().nonProdSettings === true) {
    store = createStore(
      AppReducers,
      persistedState,
      applyMiddleware(sagaMiddleware, localStorageMiddleware, logger)
    );
  } else {
    store = createStore(AppReducers, persistedState, applyMiddleware(sagaMiddleware, localStorageMiddleware));
  }

  // load the saga's
  registerWithMiddleware(sagaMiddleware);
  return store;
}

export function getStore() {
  return store;
}

export function getConfigData() {
  if (configData === null || typeof configData === "undefined") {
    configData = require("../configs/configData.json");
  }

  return configData;
}

export function getHosts() {
  if (hosts === null || typeof hosts === "undefined") {
    hosts = require("../configs/hosts.json");
  }
  return hosts;
}
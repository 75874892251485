import {isBusinessIntelligence} from "../util/workflow";

const config = require("../configs/configData.json");

const BASENAME = config[config['env']]['okta']['workforce']['basename'];
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

const oktaConfigBI = {
  oidc: {
    clientId: config[config['env']]['okta']['workforce']['client_id'],
    issuer: config[config['env']]['okta']['workforce']['issuer'],
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: config[config['env']]['okta']['workforce']['okta_testing_disablehttpscheck'],
    useInteractionCode: config[config['env']]['okta']['workforce']['use_interaction_code'],
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
  app: {
    basename: BASENAME,
  },
  wf: 'bi'
};

const oktaConfigWU = {
  oidc: {
    clientId: config[config['env']]['okta']['main']['client_id'],
    issuer: config[config['env']]['okta']['main']['issuer'],
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: config[config['env']]['okta']['main']['okta_testing_disablehttpscheck'],
    useInteractionCode: config[config['env']]['okta']['main']['use_interaction_code'],
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
  app: {
    basename: BASENAME,
  },
  wf: 'wu'
};

const oktaConfig = () => {
  return isBusinessIntelligence() ? oktaConfigBI : oktaConfigWU;
}

export default oktaConfig;
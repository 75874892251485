import React, {Component} from "react";
import {Button} from "semantic-ui-react";
import "./SwitchButton.scss";

class SwitchButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.defaultValue
        }
    }

    handleClick = (value) => {
        this.setState({active: value});
        this.props.toggleFunction(value);
    }

    render() {
        const clsLeft = (this.state.active === this.props.cta_left) ? "left active" : "left";
        const clsRight = (this.state.active === this.props.cta_right) ? "right active" : "right";
        return (
            <div className="switch-button">
                <Button.Group>
                    <Button className={clsLeft} onClick={() => this.handleClick(this.props.cta_left)}>{this.props.cta_left}</Button>
                    <Button className={clsRight} onClick={() => this.handleClick(this.props.cta_right)}>{this.props.cta_right}</Button>
                </Button.Group>
            </div>
        );
    }
}

export default SwitchButton;

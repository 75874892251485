import React, {useEffect, useMemo, useRef, useState} from "react";
import './Input.scss';
import {Label, Message} from "semantic-ui-react";
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import _ from "lodash";
import {parseFollowupConditions, parseFollowupReasons, renderFollowup, checkNumeric, inputBlur} from "../FieldsUtils";

const Input = ({settings, handleChange, order, index, followupValue, subtype, placeholder, defaultValue = ""}) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState("");
  const followup_conditions = useMemo(() => parseFollowupConditions(settings), [settings]);
  const [followupValues, setFollowupValues] = useState({radio: followupValue.radio, text: followupValue.text});
  const followup_reasons = useMemo(() => parseFollowupReasons(settings), [settings]);

  const label = settings.get('question_text');
  const subtitle = settings.get('question_subtitle');

  const _subtype = ((typeof subtype !== "undefined") && (subtype === "1")) ? "number" : "text";

  const handleInputChange = (event) => {
    event.preventDefault();
    const _value = event.target.value;
    let hasError = "";
    if ((_subtype !== 'number')) {
      hasError = (!_.isEmpty(_value) && !isAlphanumeric(_value, 'en-US', {ignore: " .,;:@_/$&"}))
        ? "Invalid value"
        : "";
      setError(hasError);
    }
    inputBlur(event, setError);
    setValue(_value);
    handleChange(index, _value, "", 'input', hasError);
  };

  const handleFollowupChange = (event, newValue, type) => {
    event.preventDefault();
    const _value = followupValues;
    _value[type] = newValue;
    setFollowupValues(_value);
    handleChange(index, value, _value, type);
  }

  return (<React.Fragment>
    <div className='questions-input'>
      <Label>{order}. {label}</Label>
      {subtitle && <p className="input-subtitle">{subtitle}</p>}
      {(_subtype === 'text') &&
        <DynamicInput handleChange={handleInputChange} placeholder={placeholder} subtype={_subtype} value={value} />
      }
      {(_subtype === 'number') &&
        <input type="text" inputMode="text" className='input-field-input' value={value} onChange={handleInputChange}
               min='0.00'
               pattern="^(?:[\$\(\)%,\.\+-\d]*\d+)$"
               onKeyDown={checkNumeric}
               onBlur={(e) => inputBlur(e, setError)}
        />
      }
      {renderFollowup(settings, 'any', followup_conditions, followup_reasons, followupValues.radio, followupValues.text, order, handleFollowupChange)}
      {(error.length > 0) && <Message
        error
        list={[error]}
      />}
    </div>
  </React.Fragment>);
};

const DynamicInput = ({subtype, value, handleChange, placeholder}) => {
  const [_value, setValue] = useState(value);
  const [strlength, setStrlength] = useState(value.toString().length);

  const handleChangeValue = (event) => {
    event.preventDefault();
    const __value = event.target.value;
    setValue(__value);
    setStrlength(__value.toString().length);
    handleChange(event);
  }

  return (
    <React.Fragment>
      {(strlength > 2) && <InputBox value={_value} onChange={handleChangeValue} placeholder={placeholder} />}
      {(strlength <= 2) && <InputText subtype={subtype} value={_value} handleInputChange={handleChangeValue} placeholder={placeholder} />}
    </React.Fragment>
  );
}

const InputText = ({subtype, value, handleInputChange, placeholder}) => {
  return (
    <input type={subtype} inputMode="text" className='input-field-input' value={value} onChange={handleInputChange}
           placeholder={placeholder} pattern="^(?:[\w\s,\.\$\:\,\@\!\_]*)$"/>);
}

const InputBox = ({value, onChange, placeholder}) => {
  const textareaRef = useRef(null);
  const [currentValue, setCurrentValue ] = useState(value);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";

    // Moving cursor to the end
    textareaRef.current.selectionStart = textareaRef.current.value.length;
    textareaRef.current.selectionEnd = textareaRef.current.value.length;
  }, [currentValue]);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    onChange(event);
  }

  return (
    <textarea ref={textareaRef} inputMode="text" className="input-field-input" autoFocus={true} value={currentValue}
              onChange={handleChange} placeholder={placeholder} data-pattern="^(?:[\w\s,\.\$\:\,\@\!\_\n]*)$" />
  );
}

export default Input;
import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import "./Home.scss";
import AddressAutoComplete from "../AddressAutoComplete";
import {getConfigData, getStore} from "../../middleware";
import {saveCurrentLocation} from "../../actions/Locations";
import {GoogleApiWrapper} from "google-maps-react";
import {getUserLocation, loadQuestionnaires} from "../../util/Util";
import Button from "../shared/Button";
import {Image, Popup} from "semantic-ui-react";

import iconInfo from '../../assets/images/icon-info.svg';
import {withTranslation} from "react-i18next";
import {isFirstRun, setFirstRun} from "../../util/firstrun";
import HowItWorks from "../HowItWorks";
// import MarketingBanner from "../../assets/images/Marketing_Banner.svg";

import _ from "lodash";
import uniqid from "uniqid";

class Home extends Component {
  constructor(props) {
    super(props);
    const openTooltip = isFirstRun();
    this.state = {
      isTooltipOpen: openTooltip,
      showUsage: openTooltip,
      contextRef: createRef(),
      tooltipRef: createRef()
    };

    loadQuestionnaires();
    const review = {
      key: uniqid(),
      locationName: "",
      address: null,
      operatingHours: null,
      photos: [],
      type: '',
      coordinates: {
        lat: -1,
        lng: -1,
      },
      questions: [],
    };

    getStore().dispatch({type: "INIT_REVIEW", data: review});
  }

  onPlaceLoaded(address) {
    try {
      let data = {
        lat: '',
        lng: '',
        formatted_address: '',
        address_components: []
      };
      if (JSON.parse(address) !== null) {
        let _address = JSON.parse(address);
        data = {
          lat: _address.geometry.location.lat,
          lng: _address.geometry.location.lng,
          formatted_address: _address.formatted_address,
          address_components: _address.address_components,
        };
      }
      getStore().dispatch(saveCurrentLocation(data));
    } catch (e) {
      console.log("Home::onPlaceLoaded error:", address, e);
    }
  }

  handleClickClear = () => {
    console.log('handleClickClear');
    getStore().dispatch({type: 'SAVE_CURRENT_LOCATION', data: {lat: '', lng: '', formatted_address: ''}});
  }

  handleClickTrack = () => {
    console.log('handleClickTrack');
    getUserLocation().then();
  }

  getTargetUrl = (isDigital) => {
    return (isDigital) ? "/digital/review": "/locations";
  }

  handleContinueClick = (isDigital) => {
    window.location.pathname = this.getTargetUrl(isDigital);
  }

  handleWUReviewClick = () => {
    window.location.href = 'https://www.westernunion.com/';
  }

  setShowUsage = (value) => {
    this.setState({showUsage: value});
  }

  setTooltipOpen = (value) => {
    if (false === value) {
      setFirstRun(value);
    }
    this.setState({isTooltipOpen: value});
  }

  isContinueDisabled = (fmt_address) => {
    console.log("isContinueDisabled:", fmt_address, (_.isEmpty(fmt_address)));
    return (_.isEmpty(fmt_address));
    // return ((!this.state.isTooltipOpen) || _.isEmpty(fmt_address));
  }

  getContent = (translation) => {
    const delimiter = "icon";
    const popContent = translation.popup.split(delimiter);

    return (
      <>
        {popContent[0]}
        &nbsp;
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6 7.61063V2.00123L7.27573 2.66048C7.50475 2.88392 7.88603 2.88526 8.12035 2.65667C8.3563 2.42647 8.35641 2.05914 8.12426 1.83265L6.41938 0.169351C6.30551 0.0582548 6.15399 0.00206468 6.00161 0.00174754L5.99703 0C5.84524 0.000138743 5.69411 0.0567314 5.57868 0.169351L3.8738 1.83265C3.64478 2.05608 3.64339 2.42807 3.87771 2.65667C4.11366 2.88686 4.49017 2.88697 4.72232 2.66048L5.4 1.99933V7.61063C5.4 7.93318 5.66863 8.19512 6 8.19512C6.33368 8.19512 6.6 7.93344 6.6 7.61063ZM10.8 6.72901V10.8293H1.2V6.72901C1.2 6.40923 0.931371 6.14634 0.6 6.14634C0.266319 6.14634 0 6.40721 0 6.72901V11.4173C0 11.5771 0.0670476 11.7226 0.176396 11.8303C0.284756 11.9354 0.434183 12 0.599187 12H11.4008C11.5662 12 11.7156 11.9348 11.8254 11.8291C11.9335 11.7237 12 11.5781 12 11.4173V6.72901C12 6.40923 11.7314 6.14634 11.4 6.14634C11.0663 6.14634 10.8 6.40721 10.8 6.72901Z" fill="black"/>
        </svg>
        &nbsp;
        { delimiter }
        {popContent[1]}
      </>
    )
  }

  render() {
    const {t} = this.props;
    const tr = t('home', {returnObjects: true});
    const fmt_address = ((this.props.formatted_address === "") || (typeof this.props.formatted_address === "undefined"))
      ? ""
      : this.props.formatted_address;

    return (
      <>
        {
          this.state.showUsage && <HowItWorks setShowUsage={this.setShowUsage}/>
        }
        {!this.state.showUsage &&
          <div className="home-search">
            <div style={{height: "100%", display: "block"}}>
              <div className={(this.state.isTooltipOpen) ? 'mask' : 'mask disabled'}>
              </div>
              <div className='title'>
                {tr.title}
              </div>
              <div className="subtitle">
                {tr.subtitle}
              </div>
              <div className="search-inputs">
                <div className="auto">
                  <AddressAutoComplete
                    onPlaceLoaded={address => this.onPlaceLoaded(address)}
                    handleClickTrack={this.handleClickTrack}
                    handleClickClear={this.handleClickClear}
                    initialValue={fmt_address}
                  />
                </div>
              </div>
              <div ref={this.state.tooltipRef} style={{display: "block"}}>
                <Button cta={tr.buttons.continue} isDisabled={this.isContinueDisabled(fmt_address)}
                        button_type="primary"
                        handleClick={()=> {
                          this.handleContinueClick(false)
                        }}/>
              </div>
              <div ref={this.state.tooltipRef} style={{display: "block"}}>
                <Button cta="or, review a digital money transfer" isDisabled={false}
                        button_type="secondary"
                        handleClick={()=> {
                          this.handleContinueClick(true)
                        }}/>
              </div>

              <Popup
                closeIcon
                open={this.state.isTooltipOpen}
                onClose={() => this.setTooltipOpen(false)}
                onOpen={() => this.setTooltipOpen(true)}
                pinned
                dimmer
                position="top center"
                context={this.state.contextRef}
                closable={true}
              >
                <Popup.Content>
                  <div className='icon-info'>
                    <Image size='tiny' style={{margin: '0 auto 12px', width: '40px', height: '40px'}} src={iconInfo}/>
                    <div className="close-popup" onClick={() => this.setTooltipOpen(false)}>
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75666 11.0265L16.8404 17.6935C17.3075 18.1331 18.0393 18.122 18.4929 17.6685C18.9683 17.1931 18.9542 16.4181 18.4619 15.9603L11.5152 9.5L18.4619 3.03968C18.9542 2.58186 18.9683 1.8069 18.4929 1.33153C18.0393 0.877984 17.3075 0.866898 16.8404 1.3065L9.75666 7.97355L2.67292 1.3065C2.20584 0.866898 1.47399 0.877984 1.02044 1.33153C0.545064 1.8069 0.559115 2.58186 1.05141 3.03968L7.99807 9.5L1.05141 15.9603C0.559116 16.4181 0.545064 17.1931 1.02044 17.6685C1.47399 18.122 2.20584 18.1331 2.67292 17.6935L9.75666 11.0265Z" fill="#FFDD00"/>
                      </svg>
                    </div>
                  </div>
                  <p className="popupContent">
                    {this.getContent(tr)}
                  </p>
                </Popup.Content>
              </Popup>
              <div ref={this.state.contextRef} className="contextRef">&nbsp;</div>
            </div>
          </div>
        }
      </>
    );
  }
}

const GoogleApiWrappedContainer = GoogleApiWrapper({
  apiKey: getConfigData().mapsApiKey
})(Home);

const mapStateToProps = state => {
  return {
    lat: state.sharedData.lat,
    lng: state.sharedData.lng,
    formatted_address: state.sharedData.formatted_address,
    address_components: state.sharedData.address_components,
  };
};

export default connect(mapStateToProps)(withTranslation()(GoogleApiWrappedContainer));

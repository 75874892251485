import {getConfigData, getStore} from '../middleware/index';
import {loadAgents, saveCurrentLocation} from "../actions/Locations";

/* global google */

export function getHeader() {
  return {
    "Content-Type": "application/json",
    Accept: "application/json"
  };
}

export function getChannelBody() {
  return {
    type: getConfigData().channel_type,
    name: "R3MOBILEAPP_6.7",
    version: "USA25"
  };
}

export function getSecurity() {
  const session = {
    id: getStore().getState().commonData.sessionId,
    auth: ""
  };
  return {
    session,
    client_ip: "202.130.186.196"
  };
}

export function getBashPath() {
  return "/us/en/"; //Need to make this dynamic
}

export function getLocaleBody() {
  return {
    country_code: "US", //Need to make these dynmaic
    language_code: "en"
  };
}

export function formatTime(time) {
  let _time;
  if (time.length === 3)
    _time = "0" + time.substring(0, 1) + ":" + time.substring(1, 3);
  else if (time.length === 4)
    _time = time.substring(0, 2) + ":" + time.substring(2, 4);
  else _time = time;

  return _time;
}

export function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  };
}

export function isLoggedin() {
  return (
    localStorage.getItem("okta-token-storage") &&
    JSON.parse(localStorage.getItem("okta-token-storage")).accessToken
  );
}

export function getUserLocation(workforce = false) {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        if (typeof google !== "undefined") {
          try {
            resolve(geocodeCoordinates(lat, lng));
          } catch (e) {
            reject(e)
          }
        }
      },
      function (failure) {
        let lat = 0;
        let lng = 0;
        let fmt_address = "";
        let address_components = [];
        const data = {
          lat: lat,
          lng: lng,
          formatted_address: fmt_address,
          address_components: address_components,
        }
        if (true === workforce) {
          getStore().dispatch({type: "SAVE_CURRENT_WF_LOCATION", data: data});
        } else {
          getStore().dispatch({type: "SAVE_CURRENT_LOCATION", data: data});
        }
        resolve({data: data});
      }
    );
  });
}

export function geocodeCoordinates(lat, lng) {
    let latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    let geocoder = new google.maps.Geocoder();

    let fmt_address = '';
    let address_components = [];

    return geocoder
      .geocode({location: latlng})
      .then((response) => {
        if (response.results[0]) {
          fmt_address = (response.results[0].formatted_address);
          address_components = (response.results[0].address_components);
          const data = {
            lat: lat,
            lng: lng,
            formatted_address: fmt_address,
            address_components: address_components,
          };
          getStore().dispatch(saveCurrentLocation(data));
          getStore().dispatch(loadAgents());
          return({data: data});
        } else {
          console.log("No results found");
          throw new Error({err: "No results found"});
        }
      })
      .catch((e) => {
        console.error("Geocoder failed due to: " + e)
        throw new Error({err: "Geocoder failed due to: " + e});
      });
}

export function loadQuestionnaires() {
  getStore().dispatch({type: "LOAD_QUESTIONNARIES"});
}

export function getStreetViewUrl(address) {
  if (address) {
    const location = encodeURI(address);
    const key = getConfigData().mapsApiKey;
    return "https://maps.googleapis.com/maps/api/streetview?size=600x300&location=" + location + "&radius=250&source=outdoor&key=" + key;
  }
  return "";
}

export function getDirectionsUrl(address) {
  if (address) {

      const location = encodeURI(address);
      return "https://www.google.com/maps/dir/?api=1&destination=" + location;
  }
  return "";
}

export function snake_case_string(str) {
  return str && str.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+\d*|\b)|[A-Z]?[a-z]+\d*|[A-Z]\d+/g)
    .map(s => s.toLowerCase())
    .join('_');
}

export function stringToTitle(str) {
  return str.split("_").map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');
}

export function getUSStatesSelectOptions() {
  return [
    {
      "value": "Alabama",
      "text": "AL",
      "key": "AL"
    },
    {
      "value": "Alaska",
      "text": "AK",
      "key": "AK"
    },
    {
      "value": "American Samoa",
      "text": "AS",
      "key": "AS"
    },
    {
      "value": "Arizona",
      "text": "AZ",
      "key": "AZ"
    },
    {
      "value": "Arkansas",
      "text": "AR",
      "key": "AR"
    },
    {
      "value": "California",
      "text": "CA",
      "key": "CA"
    },
    {
      "value": "Colorado",
      "text": "CO",
      "key": "CO"
    },
    {
      "value": "Connecticut",
      "text": "CT",
      "key": "CT"
    },
    {
      "value": "Delaware",
      "text": "DE",
      "key": "DE"
    },
    {
      "value": "District Of Columbia",
      "text": "DC",
      "key": "DC"
    },
    {
      "value": "Federated States Of Micronesia",
      "text": "FM",
      "key": "FM"
    },
    {
      "value": "Florida",
      "text": "FL",
      "key": "FL"
    },
    {
      "value": "Georgia",
      "text": "GA",
      "key": "GA"
    },
    {
      "value": "Guam",
      "text": "GU",
      "key": "GU"
    },
    {
      "value": "Hawaii",
      "text": "HI",
      "key": "HI"
    },
    {
      "value": "Idaho",
      "text": "ID",
      "key": "ID"
    },
    {
      "value": "Illinois",
      "text": "IL",
      "key": "IL"
    },
    {
      "value": "Indiana",
      "text": "IN",
      "key": "IN"
    },
    {
      "value": "Iowa",
      "text": "IA",
      "key": "IA"
    },
    {
      "value": "Kansas",
      "text": "KS",
      "key": "KS"
    },
    {
      "value": "Kentucky",
      "text": "KY",
      "key": "KY"
    },
    {
      "value": "Louisiana",
      "text": "LA",
      "key": "LA"
    },
    {
      "value": "Maine",
      "text": "ME",
      "key": "ME"
    },
    {
      "value": "Marshall Islands",
      "text": "MH",
      "key": "MH"
    },
    {
      "value": "Maryland",
      "text": "MD",
      "key": "MD"
    },
    {
      "value": "Massachusetts",
      "text": "MA",
      "key": "MA"
    },
    {
      "value": "Michigan",
      "text": "MI",
      "key": "MI"
    },
    {
      "value": "Minnesota",
      "text": "MN",
      "key": "MN"
    },
    {
      "value": "Mississippi",
      "text": "MS",
      "key": "MS"
    },
    {
      "value": "Missouri",
      "text": "MO",
      "key": "MO"
    },
    {
      "value": "Montana",
      "text": "MT",
      "key": "MT"
    },
    {
      "value": "Nebraska",
      "text": "NE",
      "key": "NE"
    },
    {
      "value": "Nevada",
      "text": "NV",
      "key": "NV"
    },
    {
      "value": "New Hampshire",
      "text": "NH",
      "key": "NH"
    },
    {
      "value": "New Jersey",
      "text": "NJ",
      "key": "NJ"
    },
    {
      "value": "New Mexico",
      "text": "NM",
      "key": "NM"
    },
    {
      "value": "New York",
      "text": "NY",
      "key": "NY"
    },
    {
      "value": "North Carolina",
      "text": "NC",
      "key": "NC"
    },
    {
      "value": "North Dakota",
      "text": "ND",
      "key": "ND"
    },
    {
      "value": "Northern Mariana Islands",
      "text": "MP",
      "key": "MP"
    },
    {
      "value": "Ohio",
      "text": "OH",
      "key": "OH"
    },
    {
      "value": "Oklahoma",
      "text": "OK",
      "key": "OK"
    },
    {
      "value": "Oregon",
      "text": "OR",
      "key": "OR"
    },
    {
      "value": "Palau",
      "text": "PW",
      "key": "PW"
    },
    {
      "value": "Pennsylvania",
      "text": "PA",
      "key": "PA"
    },
    {
      "value": "Puerto Rico",
      "text": "PR",
      "key": "PR"
    },
    {
      "value": "Rhode Island",
      "text": "RI",
      "key": "RI"
    },
    {
      "value": "South Carolina",
      "text": "SC",
      "key": "SC"
    },
    {
      "value": "South Dakota",
      "text": "SD",
      "key": "SD"
    },
    {
      "value": "Tennessee",
      "text": "TN",
      "key": "TN"
    },
    {
      "value": "Texas",
      "text": "TX",
      "key": "TX"
    },
    {
      "value": "Utah",
      "text": "UT",
      "key": "UT"
    },
    {
      "value": "Vermont",
      "text": "VT",
      "key": "VT"
    },
    {
      "value": "Virgin Islands",
      "text": "VI",
      "key": "VI"
    },
    {
      "value": "Virginia",
      "text": "VA",
      "key": "VA"
    },
    {
      "value": "Washington",
      "text": "WA",
      "key": "WA"
    },
    {
      "value": "West Virginia",
      "text": "WV",
      "key": "WV"
    },
    {
      "value": "Wisconsin",
      "text": "WI",
      "key": "WI"
    },
    {
      "value": "Wyoming",
      "text": "WY",
      "key": "WY"
    }
  ];
}
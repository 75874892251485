import {call, put, take} from "redux-saga/effects";
import {fetchContent} from "../actions/Content";

export function* getSamples(_in, _out) {
  while (true) {
    let lang = yield take(_in);
    const sample = yield call(fetchContent, lang);
    if (lang === "END") {
      _out.close();
      return;
    }
    yield put(_out, sample);
  }
}
import React, {Component} from "react";
import "./Button.scss";

class Button extends Component {
    handleClick = (event) => {
        event.preventDefault();
        if (!(this.props.isDisabled && true===this.props.isDisabled)) {
            this.props.handleClick(event);
        }
    }

    render() {
        let clName = "btn";
        if ((this.props.isDisabled) && (true===this.props.isDisabled)) {
            clName += " disabled";
        } else {
            clName += (this.props.button_type === 'primary')
                ? " btn-primary"
                : " btn-secondary";
        }
        return (
            <div className='Button' style={{...this.props.style}}>
                <button className={clName} onClick={this.handleClick} type={this.props.type || "button"}>
                    <span className='CTA'>{this.props.cta}</span>
                </button>
            </div>
        );
    }
}

export default Button;

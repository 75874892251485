import {takeLatest, call, delay, put} from "redux-saga/effects";
import { getStore } from '../middleware/index';
import {
  fetchAgentLocations,
  saveAgentData
} from '../actions/Locations';

export function* findAgentsSaga() {
  yield takeLatest(
    [
      "LOAD_AGENTS",
      "LOAD_SEARCH_AGENTS",
      "SAVE_AGENT_DATA"
      // "SAVE_AGENT_DATA_SUCCESS",
      // "SAVE_AGENT_DATA_FAILURE",
      // "SAVE_AGENT_DATA_ACCESS_FAILURE"
    ],
    findAgentsWorkerSaga
  );
}

export function* updateCurrentLocationSaga() {
  yield takeLatest("UPDATE_LOCATION", updateCurrentLocationWorker);
}

function* updateCurrentLocationWorker(actions) {
  yield put({
    type: "SET_CURRENT_LOCATION",
    lat: actions.data.lat,
    lng: actions.data.lng,
    formatted_address: actions.data.formatted_address,
    address_components: actions.data.address_components,
  });
}

function* findAgentsWorkerSaga(actions) {
  console.log('findAgentsWorkerSaga', actions);
  try {
    let store = getStore();

    switch (actions.type) {
      case "LOAD_AGENTS": {
        console.log('findAgentsWorkerSaga::data[0]', store.getState().sharedData);
        while (
          store.getState().sharedData.lat === undefined ||
          store.getState().sharedData.lng === undefined
          ) {
          yield delay(200);
        }
        console.log('findAgentsWorkerSaga::data[1]', store.getState().sharedData);
        yield call(
          fetchAgentLocations,
          store.getState().sharedData.lat,
          store.getState().sharedData.lng,
          store.getState().sharedData.formatted_address,
        );

        break;
      }
      case "LOAD_SEARCH_AGENTS": {
        yield call(fetchAgentLocations, actions.data.lat, actions.data.lng, store.getState().sharedData.formatted_address);
        break;
      }
      case "SAVE_AGENT_DATA": {
        yield call(saveAgentData, actions.data);
        break;
      }
      default:
        break;
    }
    
  } catch (error) {
    console.log("Inside findAgentsWorkerSaga error = " + error);
  }
}
import {call, put} from "redux-saga/effects";
import APIClient from "../middleware/APIClient";
import {GetAgentLocations} from "../helpers/GetAgentLocations";
import {FetchAgentLocations} from "../helpers/FetchAgentLocations";
import {UpdateAgentLocation} from "../helpers/UpdateAgentLocation";
import _ from "lodash";

const _api = new APIClient();

export function saveCurrentLocation(data) {
  return {
    type: "SAVE_CURRENT_LOCATION",
    data
  };
}

export function loadAgents() {
  return {
    type: "LOAD_AGENTS"
  };
}

export function loadSearchAgents(data) {
  return {
    type: "LOAD_SEARCH_AGENTS",
    data
  };
}

export function saveAgent(data) {
  return {
    type: "SAVE_AGENT_DATA",
    data
  };
}

export function saveAgentSuccess(data) {
  return {
    type: "SAVE_AGENT_DATA_SUCCESS",
    data
  };
}

export function saveAgentFailure(data) {
  return {
    type: "SAVE_AGENT_DATA_FAILURE",
    data
  };
}

export function saveAgentAccessFailure(data) {
  return {
    type: "SAVE_AGENT_DATA_ACCESS_FAILURE",
    data
  };
}

export function updateCurrentLocation(data) {
  return {
    type: "UPDATE_LOCATION",
    data
  }
}

//Fetching data from CS
export function* getAgentLocations(lat, lng) {
  let _getAgentLocations = new GetAgentLocations(lat, lng);
  const result = yield call(_api.triggerPostAPI, _getAgentLocations);

  if (
    result.response &&
    result.response.data &&
    result.response.data.agentLocations
  ) {
    let agents = [];
    for (
      let i = 0;
      i < result.response.data.agentLocations.agentLocation.length;
      i++
    ) {
      let agent = {};
      let marker = {};
      let rowData = {};
      let coordinates = {};
      let address = {};
      coordinates.latitude = Number(
        result.response.data.agentLocations.agentLocation[i].geoCoordinates
          .latitude
      );
      coordinates.longitude = Number(
        result.response.data.agentLocations.agentLocation[i].geoCoordinates
          .longitude
      );

      marker.coordinates = coordinates;
      marker.key = result.response.data.agentLocations.agentLocation[i].id;
      marker.id = "Marker" + i;
      marker.name = result.response.data.agentLocations.agentLocation[i].name;

      rowData.name = result.response.data.agentLocations.agentLocation[i].name;
      let distance = result.response.data.agentLocations.agentLocation[
        i
        ].distance.toString();
      if (distance) {
        distance =
          distance.substr(0, distance.indexOf(".")) +
          "." +
          distance.substr(distance.indexOf(".") + 1, 4);
      }
      rowData.distance = distance;
      address.addrLine1 =
        result.response.data.agentLocations.agentLocation[i].address.addrLine1;
      address.city =
        result.response.data.agentLocations.agentLocation[i].address.city;
      address.state =
        result.response.data.agentLocations.agentLocation[i].address.state;
      address.countryIsoCode =
        result.response.data.agentLocations.agentLocation[
          i
          ].address.countryIsoCode;
      address.postalCode =
        result.response.data.agentLocations.agentLocation[i].address.postalCode;
      rowData.address = address;
      rowData.id = i;
      rowData.contactPhone =
        result.response.data.agentLocations.agentLocation[i].contactPhone;

      let operHours =
        result.response.data.agentLocations.agentLocation[i].operatingHours;

      rowData.operatingHours = operHours;
      rowData.wuProducts =
        result.response.data.agentLocations.agentLocation[i].wuProducts;

      agent.key = result.response.data.agentLocations.agentLocation[i].id;
      agent.marker = marker;
      agent.rowData = rowData;

      agents.push(agent);
    }

    if (lat !== undefined && lng !== undefined) {
      yield put({
        type: "SET_CURRENT_LOCATION",
        lat: lat,
        lng: lng
      });
    }

    yield put({
      type: "SET_AGENT_DATA",
      agents: agents
    });
  }

  return { success: true };
}

//Fetching data from Infonow
export function* fetchAgentLocations(lat, lng, formatted_address) {
  if (lat !== 0 && lng !== 0) {
    const agentLocationReq = new FetchAgentLocations(lat, lng);
    console.log("actions fetchAgentLocations " , agentLocationReq);
    const result = yield call(_api.triggerGetAPI, agentLocationReq);
    // const result = yield call(_api.triggerGetAPI, agentLocationReq);

    console.log("actions fetchAgentLocations result" , result);
    if (
      result.response &&
      result.response.data &&
      result.response.data.results
    ) {
      let agents = [];

      for (let i = 0; i < 20; i++) {
        let agent = parseAgentsResponse(result.response.data.results[i], i);
        if (!agent) {
          break;
        }
        agents.push(agent);
      }

      //User searched location lat, lng
      if (lat !== undefined && lng !== undefined) {
        yield put({
          type: "SET_CURRENT_LOCATION",
          lat: lat,
          lng: lng,
          formatted_address: formatted_address
        });
      }

      yield put({
        type: "SET_AGENT_DATA",
        agents: agents
      });
    } else {
      let agents = [];
      yield put({
        type: "SET_AGENT_DATA",
        agents: agents
      });
    }
  } else {
    let agents = [];
    yield put({
      type: "SET_AGENT_DATA",
      agents: agents
    });
  }
}

const parseAgentsResponse = (data, order) => {
  if (_.isEmpty(data)) {
    return false;
  }
  let agent = {};
  let marker = getMarker(data, order);
  let rowData = getAgentInfo(data, marker.key, order);

  agent.key = data.id;
  agent.marker = marker;
  agent.rowData = rowData;
  return agent;
}

const getMarker = (data, order) => {
  let marker = {};
  let coordinates = {};

  coordinates.latitude = Number(
    data.latitude
  );
  coordinates.longitude = Number(
    data.longitude
  );

  marker.coordinates = coordinates;
  marker.key = data.id;
  marker.id = "Marker" + order;
  marker.name = data.name;
  return marker;
}

const getAgentAddress = (data) => {
  let address = {};
  address.addrLine1 = data.streetAddress;
  address.addrLine2 = (typeof data.streetAddress2 !== "undefined")
    ? data.streetAddress2
    : '';
  address.addrLine3 = (typeof data.streetAddress3 !== "undefined")
    ? data.streetAddress3
    : '';
  address.city = data.city;
  address.state = data.state;
  address.countryIsoCode = "US";
  address.country = data.COUNTRY_NAME;
  address.postalCode = data.postal;
  return address;
}

const getOperatingHours = (data) => {
  let operatingHours = {};
  let isClosed = false;
  let hours = [];
  let day = [];
  for (const [key, value] of Object.entries(data['detail.hours'])) {
    if (value === "Closed") {
      isClosed = true;
      hours[0] = hours[1] = '';
    } else {
      isClosed = false;
      hours = value.split('-');
    }
    day.push({
      name: key,
      isClosed: isClosed,
      open: hours[0],
      close: hours[1]
    });
  }

  operatingHours.day = day;
  return operatingHours;
}

const getDistance = (data) => {
  let distance = "";
  if (data.distance) {
    distance = data.distance.toString();
    if (distance) {
      distance =
        distance.substr(0, distance.indexOf(".")) +
        "." +
        distance.substr(distance.indexOf(".") + 1, 4);
    }
  }
  return distance;
}

const getAgentInfo = (data, markerKey, order) => {
  let rowData = {};
  rowData.name = data.name;
  rowData.agentId = (typeof data.orig_id !== "undefined")
    ? data.orig_id
    : data.id;

  rowData.address = getAgentAddress(data);
  rowData.id = order;
  rowData.key = markerKey;
  rowData.contactPhone = data.phone;
  rowData.timeZone = (typeof data.time_zone !== "undefined")
    ? data.time_zone
    : "America/Los_Angeles";
  rowData.distance = getDistance(data);
  rowData.operatingHours = getOperatingHours(data);

  return rowData;
}
//=========================

export function* saveAgentData(agentData) {
  console.log(
    "Inside saveAgentData Action, AgentData = " + JSON.stringify(agentData)
  );

  let updateAgentLocation = new UpdateAgentLocation(agentData);
  const result = yield call(_api.triggerPostAPI, updateAgentLocation);
console.log("submitted", result);
  if (result.response) {
    if (
      result.response.data &&
      result.response.data.status &&
      result.response.data.status === "success"
    ) {
      console.log("Saved successfully!!");
      yield put({
        type: "SAVE_AGENT_DATA_SUCCESS",
        data: true
      });
    } else {
      console.log("Error occurred");
      yield put({
        type: "SAVE_AGENT_DATA_FAILURE",
        data: false
      });
    }
  } else {
    console.log("Error occurred");
    yield put({
      type: "SAVE_AGENT_DATA_ACCESS_FAILURE",
      data: false
    });
  }
}
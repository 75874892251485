import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18n from "./util/i18n";
import {Provider} from "react-redux";
import {getConfigData, initialize} from "./middleware";
import {Oval} from "react-loader-spinner";
import TagManager from "./util/TagManager";
import OneTrust from "./util/OneTrust";

const config = getConfigData();
const gtmId = config[config['env']]['gtmId'];
const otDomain = config[config['env']]['oneTrustDomain'];
TagManager.initialize(gtmId);
OneTrust.initialize(otDomain);


let store = initialize();

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <React.Suspense fallback={<Oval className="loader" heigth="100" width="100" color='yellow' ariaLabel='loading'/>}>
        <App/>
      </React.Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import _ from "lodash";
import config from "../configs/oktaConfig";
import {OktaAuth} from "@okta/okta-auth-js";

const getWorkflow = () => {
  let wf = localStorage.getItem("wu-workflow");
  if (_.isEmpty(wf)) {
    wf = "wu";
    setWorkflow(wf);
  }
  return wf;
}

const setWorkflow = (wf) => {
  localStorage.setItem("wu-workflow", wf);
}

const isBusinessIntelligence = () => {
  return (getWorkflow() === "bi");
}

const getOktaAuth = () => {
  const oktaCFG = config().oidc;
  return new OktaAuth(oktaCFG);
}

export {getWorkflow, setWorkflow, isBusinessIntelligence, getOktaAuth}
import {takeLatest, put} from "redux-saga/effects";
import { getStore } from '../middleware/index';

export function* initFeedback() {
  yield takeLatest("INIT_REVIEW", initFeedbackSaga);
}

export function* updateReview() {
  yield takeLatest(
    [
        "SAVE_PHOTOS",
        "UPDATE_HOURS",
        "UPDATE_ADDRESS",
        "SAVE_QUESTIONS",
    ],
    updateReviewSaga);
}

export function* initFeedbackSaga(actions) {
  try {
    let store = getStore();
    store.getState().feedback = actions.data;
    yield put("FEEDBACK_SUCCESS");
  } catch (e) {
    console.error("Inside initFeedbackSaga error = " + e);
  }
}

export function updateReviewSaga(actions) {
  try {
    let store = getStore();
    switch (actions.type) {
      case "SAVE_PHOTOS":
        store.getState().feedback.photos = actions.data;
        break;
      case "UPDATE_HOURS":
        store.getState().feedback.operatingHours = actions.operatingHours;
        break;
      case "UPDATE_ADDRESS":
        store.getState().feedback.address = actions.address;
        break;
      case "SAVE_QUESTIONS":
        store.getState().feedback.questions = actions.questions;
        break;
      default:
    }
  } catch (e) {
    console.error("Inside updateReviewSaga error = " + e);
  }
}
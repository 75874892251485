import Resizer from "react-image-file-resizer";
import imgPlaceholder from "../assets/images/icon-placeholder.svg";

const countPristine = (photos) => {
  let photosLen = 0;
  photos.forEach((current) => {
    if (false === current.pristine) {
      photosLen++;
    }
  });
  return photosLen;
}

const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1600,
      1600,
      "JPEG",
      40,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
};

const loadImage = (files, photos, setPhotos, setIsCameraDisabled) => {
  /* Map each file to a promise that resolves to an array of image URI's */
  Promise.all(files.map(file => {
    return (new Promise((resolve, reject) => {
      try {
        resizeFile(file).then(img => {
          let val = {src: img, size: file.size};
          resolve(val);
        });
      } catch (e) {
        reject(e);
      }
    }));
  })).then(val => {
    let _photos = [];

    let i = 0;
    for (const photo of photos) {
      if ((true === photo.pristine) && (val.length > i)) {
        _photos.push({src: val[i].src, pristine: false, size: val[i].size});
        i++;
      } else {
        _photos.push(photo);
      }
    }
    /* Once all promises are resolved, update state with image URI array */
    setPhotos(_photos);
  }, error => {
    console.error("loadImages_error::" + error);
  });
}

const initPhotos = (photos = []) => {
  const photoArr = photos;
  if (photos.length <= 4) {
    let pad = 4 - photos.length;
    for (let i = 0; i < pad; i++) {
      photoArr.push({src: imgPlaceholder, pristine: true, size: 0});
    }
  }
  let photosLen = countPristine(photoArr);
  return {
    photos: photoArr,
    isCameraDisabled: (photosLen < 1)
  };
}

const getPhotosSize = (photos) => {
  let result = 0;
  for (const photo of photos) {
    if (false === photo.pristine) {
      result += photo.size;
    }
  }
  if (isNaN(result)) {
    result = 0;
  }
  return result;
}

export {initPhotos, countPristine, resizeFile, loadImage, getPhotosSize};
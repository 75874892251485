const Snippets = {
  tags: function ({domain}) {
    const sdk = document.createElement('script');
    sdk.setAttribute('src', "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
    sdk.setAttribute('charset', "UTF-8");
    sdk.setAttribute("data-domain-script", domain);
    const script = `
            /* OneTrust Cookies Consent Notice start for onthego.westernunion.com */
              function OptanonWrapper() {
                window.dataLayer.push({event: 'OneTrustGroupsUpdated' });
              }
            /* OneTrust Cookies Consent Notice end for onthego.westernunion.com */`;

    return {
      script,
      sdk
    }
  }
}

const OneTrust = {
  ot: function (args) {
    const snippets = Snippets.tags(args);

    const script = () => {
      const Script = document.createElement('script');
      Script.innerHTML = snippets.script;
      return Script;
    }

    const sdk = snippets.sdk;

    return {
      script,
      sdk
    }
  },
  initialize: function (domain) {
    const ot = this.ot({
      domain: domain
    })
    document.head.insertBefore(ot.script(), document.head.childNodes[0]);
    document.head.insertBefore(ot.sdk, document.head.childNodes[0]);
  },
}

module.exports = OneTrust;
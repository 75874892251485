import React, {useEffect, useState} from "react";
import {Segment, Sidebar} from "semantic-ui-react";
import Header from "../header";
import {LoginCallback, SecureRoute, Security} from "@okta/okta-react";
import './Main.scss';
import {BrowserRouter, Route} from "react-router-dom";
import Home from "../Home";
import Locations from "../Locations";
import {loadQuestionnaires} from "../../util/Util";
import HowItWorks from "../HowItWorks";
import LocationDetails from "../LocationDetails";
import Review from "../LocationFeedback/Review";
// import Login from "../Login";
import 'semantic-ui-css/semantic.min.css'
import {getOktaAuth, isBusinessIntelligence} from "../../util/workflow";
import WFLogin from "../Login/WFLogin";
import Switch from "react-router-dom/es/Switch";
import Redirect from "react-router-dom/es/Redirect";
import DigitalFeedback from "../DigitalFeedback/DigitalFeedback";

const Main = () => {
  const [oktaAuth, setAuth] = useState(getOktaAuth());

  useEffect(() => {
    const _oktaAuth = getOktaAuth();
    setAuth(_oktaAuth);
    loadQuestionnaires();
  }, [setAuth]);

  const getLastLogin = () => {
    return isBusinessIntelligence() ? '/workforce-login' : '/login';
  }

  const logout = async () => {
    const basename = window.location.origin + '/';
    try {
      await oktaAuth.signOut({postLogoutRedirectUri: basename});
    } catch (err) {
      console.error("logout error", err);
    }
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location = originalUri || '/';
  };

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    window.location.href = getLastLogin();
  };

  // const setCorsErrorModalOpen = false;

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <Segment stacked vertical>
            <main>
              <Security oktaAuth={oktaAuth}
                        onAuthRequired={customAuthHandler}
                        restoreOriginalUri={restoreOriginalUri}
              >
                <Header logout={logout}/>
                <BrowserRouter>
                  <Switch>
                    <SecureRoute path='/' exact={true} component={Home}/>
                    <Route exact path="/workforce-login" component={WFLogin}/>
                    <Route exact path="/login" component={WFLogin}/>
                    {/*<Route exact path="/login" render={() => <Login {...{setCorsErrorModalOpen}} />}/>*/}
                    <Route path="/login/callback" component={LoginCallback}/>

                    <SecureRoute path='/locations' render={(...props) => <Locations {...props} />}/>
                    <Route path='/how-it-works' render={(...props) => <HowItWorks {...props} />}/>
                    <SecureRoute path='/location/details/key/:key'
                                 render={(...props) => <LocationDetails {...props} />}/>
                    <SecureRoute path='/location/review' render={(...props) => <Review {...props} />}/>
                    <SecureRoute path='/digital/review' render={(...props) => <DigitalFeedback {...props} />}/>

                    <Redirect to={getLastLogin()} />
                  </Switch>
                </BrowserRouter>
              </Security>
            </main>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
}

export default Main;
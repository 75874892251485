import React, {useMemo, useState} from "react";
import './Rating.scss';
import {Label, Rating as RatingStars} from "semantic-ui-react";
import {parseFollowupConditions, parseFollowupReasons, renderFollowup} from "../FieldsUtils";

const Rating = ({settings, handleChange, order, index, followupValue, defaultValue = 0}) => {
  const [rating, setRating] = useState(defaultValue);
  const [followup_checked, setFollowupChecked] = useState({radio: followupValue.radio, text: followupValue.text});
  const label = settings.get('question_text');

  const subtitle = settings.get('question_subtitle');
  const low = settings.get('question_options_scale_low');
  const high = settings.get('question_options_scale_high');
  const followup_conditions = useMemo(() => parseFollowupConditions(settings), [settings]);
  const followup_reasons = useMemo(() => parseFollowupReasons(settings), [settings]);

  const handleFollowupChange = (event, newValue, type) => {
    event.preventDefault();
    const _value = followup_checked;
    _value[type] = newValue;
    setFollowupChecked(_value);
    handleChange(index, rating, _value, type);
  }

  const handleRate = (e, data) => {
    e.preventDefault();
    setRating(data.rating);
    handleChange(index, data.rating, followup_checked);
  }

  return (
    <React.Fragment>
      <div className="questions-rating">
        <Label>{order}. {label}</Label>
        {subtitle && <p className="input-subtitle">{subtitle}</p>}
        <div className="rating-stars">
          <RatingStars className="rating-stars-icons" maxRating={5} rating={rating} onRate={handleRate}
                       icon='star' size='massive'/>
        </div>
        <div className="labels"><span className='to-left'>{low}</span><span
          className='to-right'>{high}</span></div>
        {renderFollowup(settings, rating, followup_conditions, followup_reasons, followup_checked.radio, followup_checked.text, order, handleFollowupChange)}
      </div>
    </React.Fragment>
  );
}

export default Rating;

import React, {useContext, useEffect, useRef, useState} from "react";
import {Image, Modal} from "semantic-ui-react";
import "./Review.scss"
import Button from "../../shared/Button";
import SubmissionModel from "../../../helpers/SubmissionModel";
import {useSelector, useStore} from "react-redux";
import {useTranslation} from "react-i18next";
import {MainFeedbackContext} from "../LocationFeedback";
import PhotosCard from "./cards/photosCard";
import AddressCard from "./cards/addressCard";
import HoursCard from "./cards/hoursCard";
import QuestionsCard from "./cards/questionsCard";
import {getStreetViewUrl} from "../../../util/Util";
import {Oval} from "react-loader-spinner";

const successContent = (setShowNotification, currentAddress, locationName, address) => {
  return (
    <>
      <h1>Congratulations!</h1>
      <p>You have successfully submitted your feedback for Sending Money from:</p>
      <Modal.Description>
        <div className="body">
          <Image size='small' src={getStreetViewUrl(currentAddress)} wrapped/>
          <p>
            <strong>{locationName}</strong>
            {address.addrLine1 &&
              <p>{address.addrLine1},</p>
            }
            <p>{address.city}, {address.state} {address.postalCode}</p>
            <p>Tel: {address.contact}</p>
          </p>
        </div>
      </Modal.Description>
      <div className="action-box">
        <Button cta="Return Home" button_type="primary"
                handleClick={(event) => {
                  setShowNotification(false);
                  window.location.href = '/';
                }}/>
      </div>
    </>
  );
}

const failContent = (setShowNotification, notificationMsg, setReview) => {
  return (
    <>
      <h1>Something went wrong!</h1>
      <p>{notificationMsg}</p>

      <div className="action-box">
        <Button cta="Close" button_type="primary"
                handleClick={(event) => {
                  setReview({});
                  window.location.href = '/';
                  setShowNotification(false);
                }}/>
      </div>
    </>
  );
}

const renderModal = (isLoading, hasSuccess, currentAddress, locationName, address, showNotification, setShowNotification, notificationMsg, setReview) => {
  return (<Modal
    open={showNotification}
    dimmer="blurring"
    size="small"
    className="congrats"
  >
    <Modal.Content>
      {isLoading &&
        <Oval className="loader" heigth="100" width="100" color='yellow' ariaLabel='loading'/>}
      {!isLoading && ((true === hasSuccess) ? successContent(setShowNotification, currentAddress, locationName, address) : failContent(setShowNotification, notificationMsg, setReview))}
    </Modal.Content>
  </Modal>);
}

const Review = ({isBI, locationName, getServiceType, agent}) => {
  const {review, setReview} = useContext(MainFeedbackContext);
  const {t} = useTranslation();
  const tr = t('review', {returnObjects: true});

  const showModal = useSelector((state) => state.agentDataMap.showNotification);
  const [showNotification, setShowNotification] = useState(showModal);

  const fieldRef = useRef(null);

  const currentAddress = review.address.addrLine1 + ", " + review.address.city + ", " + review.address.state + ", " + review.address.postalCode;
  const store = useStore();
  let hasSuccess, isLoading, notificationMsg;
  hasSuccess = useSelector((state) => state.agentDataMap.isAgentDetailSavedSuccess);
  isLoading = useSelector((state) => state.agentDataMap.loading);
  notificationMsg = useSelector((state) => state.agentDataMap.notificationMsg);

  useEffect(() => {
    fieldRef.current.scrollIntoView({
      behavior: "smooth",
    });

    let newReview = review;
    newReview.agent = agent;
    setReview(newReview);
  }, [review, setReview, agent]);

  const submitReview = () => {
    let token = "";
    if (
      localStorage.getItem("okta-token-storage") &&
      JSON.parse(localStorage.getItem("okta-token-storage")).idToken
    ) {
      token = JSON.parse(
        localStorage.getItem("okta-token-storage")
      ).idToken.claims.email;
    }
    let model;
    const flag = isBI() ? "bi" : "wu";
    const type = getServiceType();
    model = new SubmissionModel(review, flag, type, token);
    store.dispatch({type: "SAVE_AGENT_DATA", data: model});

    setShowNotification(true);
  }

  return (
    <div ref={fieldRef}>
      <p id="review-title">{tr.subtitle}</p>
      <section className="review-section">
          <PhotosCard isBI={isBI	} />
      </section>
      {!isBI() &&
        <section className="review-section">
          <AddressCard locationName={locationName}/>
        </section>}
      <section className="review-section">
        <HoursCard/>
      </section>
      <section className="review-section">
          <QuestionsCard getServiceType={getServiceType} isBI={isBI} />
      </section>
      <div className='action-box'>
        <Button cta={tr.buttons.submit} handleClick={submitReview} button_type="primary"/>
      </div>


      {renderModal(isLoading, hasSuccess, currentAddress, locationName, review.address, showNotification, setShowNotification, notificationMsg, setReview)}
    </div>
  );
}

export default Review;
import React, {useMemo, useState} from "react";
import './Select.scss';
import {Dropdown, Label} from "semantic-ui-react";
import {parseFollowupConditions, parseFollowupReasons, parseOptions, renderFollowup} from "../FieldsUtils";
import uniqid from "uniqid";
import _ from "lodash";

const toKey = (value, options) => {
    if (!_.isEmpty(options)) {
      for (const option of options) {
        if (
          (value.replace(/ /g, '').toLowerCase() === option.value.replace(/ /g, '').toLowerCase()) ||
          (value.replace(/ /g, '').toLowerCase() === option.text.replace(/ /g, '').toLowerCase())
        ) {
          return option.value;
        }
      }
    }
    return "";
}

const Select = ({settings, handleChange, order, index, followupValue, defaultValue = ""}) => {
  const [followupValues, setFollowupValues] = useState({radio: followupValue.radio, text: followupValue.text});
  const label = settings.get('question_text');
  const subtitle = settings.get('question_subtitle');
  const options = useMemo(() => parseOptions(settings, 'select'), [settings]);
  const followup_conditions = useMemo(() => parseFollowupConditions(settings), [settings]);
  const followup_reasons = useMemo(() => parseFollowupReasons(settings), [settings]);
  const [value, setValue] = useState(toKey(defaultValue, options));
  const handleFollowupChange = (e, newValue, type) => {
    e.preventDefault();
    const _value = followupValues;
    _value[type] = newValue;
    setFollowupValues(_value);
    handleChange(index, value, _value, type);
  }

  const handleSelectChange = (e, data) => {
    e.preventDefault();
    setValue(data.value);
    let newVal = data.value;
    for (let myOption of options) {
      if (myOption.value === newVal) {
        newVal = myOption.text;
        break;
      }
    }
    handleChange(index, newVal, followupValues);
  }

  return (
    <React.Fragment>
      <div className="questions-dropdown">
        <Label>{order}. {label}</Label>
        {subtitle && <p className="input-subtitle">{subtitle}</p>}
        <Dropdown
          key={uniqid()}
          fluid
          selection
          defaultValue={value}
          options={options}
          onChange={handleSelectChange}
        />
        {renderFollowup(settings, value, followup_conditions, followup_reasons, followupValues.radio, followupValues.text, order, handleFollowupChange)}
      </div>
    </React.Fragment>
  );
}

export default Select;

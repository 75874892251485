import uniqid from "uniqid";
import ReactCardFlip from "react-card-flip";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import AddPhotos from "../../AddPhotos";


const PhotosCard = ({isBI}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const {t} = useTranslation();
  const tr = t('review', {returnObjects: true});

  const handleCtaPhotos = () => {
    setIsFlipped(false);
  };

  return (
    <React.Fragment>
      <div className="section-title">
        <h2>{tr.sections.photos}</h2>
        <EditIcon className="edit-icon" onClick={() => {
          setIsFlipped(true);
        }}/>
      </div>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div className={!isFlipped ? 'review-photos' : 'card-hidden'}>
          <AddPhotos key={uniqid()} isBI={isBI} isFeedback={false} />
        </div>
        <div className={isFlipped ? '' : 'card-hidden'}>
          <AddPhotos isEdit={true} cta={t('common', {returnObjects: true}).save}
              handleCta={handleCtaPhotos} isBI={isBI} isFeedback={true} />
        </div>
      </ReactCardFlip>
    </React.Fragment>
  );
}

export default PhotosCard;
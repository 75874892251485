const findAgentReducer = (state = {}, action={}) => {
  switch (action.type) {
    case "GET_AGENTS":
      return { ...state };
    case "SET_AGENT_DATA":
      return { ...state, agents: action.agents };
    case "SAVE_AGENT_DATA":
      return { ...state, showNotification: false, loading: true };
    case "SAVE_AGENT_DATA_SUCCESS":
      return {
        ...state,
        isAgentDetailSavedSuccess: true,
        loading: false,
        notificationMsg:
          "You’ve successfully submitted the agent location information, thank you!",
        showNotification: true
      };
    case "SAVE_AGENT_DATA_FAILURE":
      return {
        ...state,
        isAgentDetailSavedSuccess: false,
        loading: false,
        notificationMsg:
          "An error occurred while submitting the information for review, please try again",
        showNotification: true
      };
    case "SAVE_AGENT_DATA_ACCESS_FAILURE":
      return {
        ...state,
        isAgentDetailSavedSuccess: action.data,
        loading: false,
        notificationMsg:
          "An error occurred while submitting the information for review, please try again",
        showNotification: true,
        tokenExpired: true
      };
    default:
      return state;
  }
};
export default findAgentReducer;

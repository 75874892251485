import React, {useContext, useEffect, useRef, useState} from "react";
import {useSelector, useStore} from "react-redux";
import './Questionnaires.scss';
import {Boolean, Input, Rating, Scale, Select} from "../../shared/Fields";
import {useTranslation} from "react-i18next";
import {Oval} from "react-loader-spinner";
import _ from "lodash";
import uniqid from 'uniqid';
import {MainFeedbackContext} from "../LocationFeedback";
import Button from "../../shared/Button";

const Questionnaires = ({getServiceType, handleCta, nextStep, isEdit, isBI}) => {
  const {review, setReview} = useContext(MainFeedbackContext);
  const defaultServiceType = 'send-money';
  const store = useStore();
  const {t} = useTranslation();
  const tr = t('questions', {returnObjects: true});
  const trCommon = t('common', {returnObjects: true});
  const _lang = store.getState().sharedData.language;
  const lang = (!_.isEmpty(_lang)) ? _lang : 'en';
  const serviceType = (typeof getServiceType() === "undefined")
    ? defaultServiceType
    : getServiceType();
  const loaded = useSelector(state => state.questions.quesitonsLoaded);
  const questions = useSelector(state => state.questions);
  const [fields, setFields] = useState(review.questions);

  const fieldRef = useRef(null);

  useEffect(() => {
    if (loaded) {
      if (_.isEmpty(fields)) {
        const qrs = [];
        const list = store.getState().questions[lang][serviceType];
        let index = 1;
        list.forEach((value) => {
          let has_followup = (value.get('ask_followup') !== "none");
          let reason = (value.get('followup_reason')) ? value.get('followup_reason') : '';
          let followupText = (value.get('followup_text')) ? value.get('followup_text') : '';
          let z = {
            index: index,
            field_type: value.get('question_type'),
            question: value.get('question_text'),
            response: value.get('value'),
            has_followup: has_followup,
            followup_question: value.get('followup_question'),
            followup_reason: reason,
            followup_text: followupText
          };
          qrs.push(z);
          index++;
        });
        setFields(qrs);
      }
      fieldRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [loaded, fields, setFields, store, lang, serviceType]);

  const redirectReview = () => {
    if (!_.isEmpty(fields)) {
      const newReview = review;
      newReview.questions = fields;
      setReview(newReview);

      if (typeof handleCta === 'function') {
        handleCta();
      }
      if (typeof nextStep === 'function') {
        nextStep();
      }
    }
  }

  const updateResponse = (index, value, followup, type) => {
    const _fields = fields;
    _fields[index].response = value;
    if (type === "radio") {
      _fields[index].followup_reason = followup.radio;
    } else {
      _fields[index].followup_text = followup.text;
    }
    setFields(_fields);
  }

  const renderItems = (_questions) => {
    let item = [];
    let order = 1;
    const fnc = updateResponse;

    if (typeof _questions[lang] !== "undefined") {
      _questions[lang][serviceType].forEach((value, index) => {
        const key = uniqid();
        const defaultVal = (!_.isEmpty(fields[index])) ? fields[index].response : "";
        const question_type = value.get("question_type");
        const question_subtype = value.get("is_input_numeric");
        const question_placeholder = value.get("text_input_placeholder");

        const followup_value = {radio: "", text: ""};
        if (typeof review.questions[index] !== "undefined") {
          followup_value.radio = review.questions[index].followup_reason;
          followup_value.text = review.questions[index].followup_text;
        }
        switch (question_type) {
          case "select":
            item.push(
              <>
                <Select settings={value} order={order} index={index} handleChange={fnc} key={key}
                        defaultValue={defaultVal} followupValue={followup_value}/>
              </>
            );
            break;
          case "boolean":
            item.push(
              <>
                <Boolean settings={value} order={order} index={index} handleChange={fnc} key={key}
                         defaultValue={defaultVal} followupValue={followup_value}/>
              </>
            );
            break;
          case "rating":
            item.push(
              <>
                <Rating settings={value} order={order} index={index} handleChange={fnc} key={key}
                        defaultValue={defaultVal} followupValue={followup_value}/>
              </>
            );
            break;
          case "scale":
            item.push(
              <>
                <Scale settings={value} order={order} index={index} handleChange={fnc} key={key}
                       defaultValue={defaultVal} followupValue={followup_value}/>
              </>
            );
            break;
          default:
            item.push(
              <>
                <Input settings={value} order={order} index={index} handleChange={fnc} key={key}
                       followupValue={followup_value} subtype={question_subtype} placeholder={question_placeholder}
                       defaultValue={defaultVal}/>
              </>
            )
        }
        order++;
      });
      return (
        item.map((_item) => {
          return (<div key={uniqid()} className="form-field">{_item}</div>)
        })
      );
    }
  }

  const continueCTA = isBI() ? tr.buttons.next_bi : tr.buttons.next;

  return (
    <div ref={fieldRef}>
      <div className='Questionnaires'>
        <p>{tr.subtitle}</p>
        {!loaded && <Oval key={uniqid()} heigth="100" width="100" color='yellow' ariaLabel='loading'/>}
        {loaded && renderItems(questions)}

      </div>
      <div className='action-box'>
        <Button 
          className='save-questions' 
          cta={
            isEdit ? trCommon.save : continueCTA
            //continueCTA
          }
          button_type="primary"
          handleClick={redirectReview}
        />
      </div>
    </div>
  );
}

export default Questionnaires;

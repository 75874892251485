import axios from "axios";

class APIClient {

  async fetchPostAPI(request) {
    let url = request.getPostURL();
    const body = request.body();

    console.log("fetchPostAPI APIClient Request = " + JSON.stringify(body));

    // let myHeaders = new Headers();
    // myHeaders.append('Access-Control-Allow-Origin', '*');
    // myHeaders.append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
    // let token = "";
    // if (
    //   localStorage.getItem("okta-token-storage") &&
    //   JSON.parse(localStorage.getItem("okta-token-storage")).accessToken
    // ) {
    //   token =  JSON.parse(
    //     localStorage.getItem("okta-token-storage")
    //   ).accessToken.accessToken;
    //   // myHeaders.append('authorizationToken', token);
    // }

    // console.log("fetchPostAPI APIClient Request {0.1} ", JSON.stringify(myHeaders));
    //
    // const myInit = { method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //     'authorizationToken': 'Bearer ' + token,
    //     'Authorization': 'Bearer ' + token
    //   },
    //   mode: 'no-cors',
    //   body: JSON.stringify(body)
    // };

    // const myRequest = new Request(url, myInit);
    // console.log("fetchPostAPI APIClient Request {0} ", myInit);


    //'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token'
    fetch(url,  {
      method: 'POST',
      credentials: "include",
      // mode: 'no-cors',
      // redirect: 'follow',
      // origin: 'https://otgapi.wu.sisuville.com/wu-onthego-submission-API',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        // 'authorizationToken': token
      },
      body: JSON.stringify(body)
    }).then(function(response) {
      console.log("fetchPostAPI APIClient Response [1] ", response);
      return response;
    }).then(function(response) {
      console.log("fetchPostAPI APIClient Response [2] ", response);
      return { response: response, status: "SUCCESS", error: null };
    }).catch(function(error){
      console.log("fetchPostAPI APIClient Response [error] ",error);
      return { response: null, status: "FAILURE", error: error };
    });
  }

  async triggerPostAPI(request) {
    let url = request.getPostURL();
    const body = request.body();
    const header = request.getHeader();

    console.log("Post APIClient Request = " + JSON.stringify(body));

    return axios
      .post(url, body, header)
      .then(function(response) {
        console.log("Post APIClient Response = " + JSON.stringify(response));
        return { response: response, status: "SUCCESS", error: null };
      })
      .catch(function(error) {
        console.log(error);
        return { response: null, status: "FAILURE", error: error };
      });
  }

  async triggerGetAPI(request) {
    console.log("Get APIClient Request obj = ", request);
    let url =
      "https://" +
      request.host() +
      "/" +
      request.context() +
      request.queryParameter();

    const header = request.getHeader();
    console.log("Get APIClient Request URL = " + url);

    return axios
      .get(url,header)
      .then(function(response) {
        console.log("Get APIClient Response = " + JSON.stringify(response));
        return { response: response, status: "SUCCESS", error: null };
      })
      .catch(function(error) {
        console.log(error);
        return { response: null, status: "FAILURE", error: error };
      });
  }
}

export default APIClient;

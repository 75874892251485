import {getCurrentBrand} from "../util/brandselector";

export default class SubmissionModel {
  structure = {
    id: "",
    submitter: "",
    name: "",
    type: "",
    flag: "",
    locationName: "",
    brand: "",
    address: {
      addrLine1: "",
      addrLine2: "",
      addrLine3: "",
      state: "",
      city: "",
      postalCode: "",
      contactPhone: ""
    },
    coordinates: {
      latitude: "",
      longitude: ""
    },
    operatingHours: {
      days: [],
    },
    agent: {
      id: "",
      name: "",
      locationId: ""
    },
    questions: [],
    images: [],
    timestamp: "",
    date: "",
    dateFormat: "mm-dd-yyyy HH:mm:ss"
  };

  constructor(data, flag = "wu", serviceType, token) {
    let now = new Date();
    this.structure.timestamp = now.getTime();
    this.structure.date = now.getMonth() + "-" + now.getDate() + "-" + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
    this.structure.type = serviceType;
    this.structure.submitter = token;
    return this.parse(data, flag);
  }

  parse = (data, flag) => {
    let name = (data.agent && data.agent.rowData && data.agent.rowData.name)
      ? data.agent.rowData.name
      : data.locationName;
    if ("" === name) {
      name = data.key;
    }

    this.structure.brand = getCurrentBrand();
    this.structure.id = data.key;
    this.structure.name = name;
    this.structure.flag = flag;
    this.structure.locationName = data.locationName;
    this.structure.coordinates.latitude = data.coordinates.lat;
    this.structure.coordinates.longitude = data.coordinates.lng;
    if (data.agent && data.agent.rowData) {
      this.structure.agent.id = data.agent.rowData.id;
      this.structure.agent.name = data.agent.rowData.name;
      this.structure.agent.locationId = data.agent.rowData.key;
    }

    if (flag !== "di") {
      this.parseAddress(data.address);

      this.parseHours(data.operatingHours);
    }

    this.parseImages(data.photos);

    this.parseQuestions(data.questions);

    return this.structure;
  }

  parseAddress = (address) => {
    for (let fieldName in address) {
      this.structure.address[fieldName] = address[fieldName];
    }
  }

  parseHours = (operatingHours) => {
    for (let day of Object.entries(operatingHours.day)) {
      this.structure.operatingHours.days.push(day);
    }
  }

  parseImages = (images) => {
    for (const image of images) {
      if (!image.pristine) {
        this.structure.images.push(image.src);
      }
    }
  }

  parseQuestions = (questions) => {
    // @todo improve this parsing and validations
    for (const question of questions) {
      this.structure.questions.push(question);
    }
  }
}
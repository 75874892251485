import React, {Component} from "react";
import {connect} from "react-redux";
import 'rc-drawer/assets/index.css';
import 'rc-menu/assets/index.css';
import './SideMenu.scss';
import {Divider, Menu, Sidebar,} from "semantic-ui-react";
import {withOktaAuth} from "@okta/okta-react";
import {withTranslation} from "react-i18next";
// import {getStore} from "../../middleware";

class SideMenu extends Component {

  constructor(props) {
    super(props);
    const isAuth = (props.oktaAuth.authStateManager._authState)
      ? props.oktaAuth.authStateManager._authState.isAuthenticated
      : false
    ;
    const lang = (this.props.language) ? this.props.language : "en";
    this.props.i18n.changeLanguage(lang);
    this.state = {
      isAuth: isAuth,
      selectedLang: lang,
      username: "",
      languageOptions: [
        // { key: 'Arabic', text: 'Arabic', value: 'Arabic' },
        // { key: 'Chinese', text: 'Chinese', value: 'Chinese' },
        // { key: 'Danish', text: 'Danish', value: 'Danish' },
        // { key: 'Dutch', text: 'Dutch', value: 'Dutch' },
        {key: 'English', text: 'English (US)', flag: 'us', value: 'en'},
        // { key: 'French', text: 'French', value: 'French' },
        // { key: 'German', text: 'German', value: 'German' },
        // { key: 'Greek', text: 'Greek', value: 'Greek' },
        // { key: 'Hungarian', text: 'Hungarian', value: 'Hungarian' },
        // { key: 'Italian', text: 'Italian', value: 'Italian' },
        // { key: 'Japanese', text: 'Japanese', value: 'Japanese' },
        // { key: 'Korean', text: 'Korean', value: 'Korean' },
        // { key: 'Lithuanian', text: 'Lithuanian', value: 'Lithuanian' },
        // { key: 'Persian', text: 'Persian', value: 'Persian' },
        // { key: 'Polish', text: 'Polish', value: 'Polish' },
        // { key: 'Portuguese', text: 'Portuguese', value: 'Portuguese' },
        // { key: 'Russian', text: 'Russian', value: 'Russian' },
        {key: 'Spanish', text: 'Spanish', flag: 'es', value: 'es'},
        // { key: 'Swedish', text: 'Swedish', value: 'Swedish' },
        // { key: 'Turkish', text: 'Turkish', value: 'Turkish' },
        // { key: 'Vietnamese', text: 'Vietnamese', value: 'Vietnamese' },
      ]
    }
  }

  // switchLanguage = (evt, {value}) => {
  //   evt.preventDefault();
  //   this.props.i18n.changeLanguage(value);
  //   getStore().dispatch({type: "SET_LANGUAGE", language: value});
  //   this.props.setOpen(false);
  // }

  componentDidMount() {
    let profile;
    let userName = "";
    this.props.oktaAuth.getUser().then((info) => {
      profile = info;
      userName = (profile) ? profile.given_name.toUpperCase() + ", " + profile.family_name[0].toUpperCase() : '';
      this.setState({username: userName});
    }).catch((err) => {
      console.error(err);
    });
  }

  protectedMenu = (tr) => {
    return (
      <div>
        <Menu.Item className="profile">
          <div className='profile-name'>{tr.welcome}, {this.state.username}</div>
        </Menu.Item>
        <Menu.Item as='a' className="menu-option" link={true} href='/'>
          {tr.search}
        </Menu.Item>
        <Menu.Item as='a' className="menu-option"  link={true} href='/how-it-works'>
          How It Works
        </Menu.Item>
        {/*<Menu.Item as='a' className="menu-option">*/}
        {/*  <Dropdown*/}
        {/*    placeholder={tr.select_lang}*/}
        {/*    fluid*/}
        {/*    search*/}
        {/*    selection*/}
        {/*    defaultValue={this.state.selectedLang}*/}
        {/*    options={this.state.languageOptions}*/}
        {/*    onChange={this.switchLanguage}*/}
        {/*  />*/}
        {/*</Menu.Item>*/}
        <Menu.Item as='a' className="menu-option" onClick={() => {
          this.props.logout()
        }}>
          {tr.logout}
        </Menu.Item>
      </div>
    );
  }

  publicMenu = (tr) => {
    return (
      <div>
        <Menu.Item as='a' className="menu-option" link={true} href='/login'>
          {tr.login}
        </Menu.Item>
        <Menu.Item as='a' className="menu-option">
          {tr.register}
        </Menu.Item>
        <Menu.Item as='a' className="menu-option"  link={true} href='/how-it-works'>
          How It Works
        </Menu.Item>
        {/*<Menu.Item as='a' className="menu-option">*/}
        {/*  <Dropdown*/}
        {/*    placeholder={tr.select_lang}*/}
        {/*    fluid*/}
        {/*    search*/}
        {/*    selection*/}
        {/*    defaultValue={this.state.selectedLang}*/}
        {/*    options={this.state.languageOptions}*/}
        {/*    onChange={this.switchLanguage}*/}
        {/*  />*/}
        {/*</Menu.Item>*/}
      </div>
    );
  }

  render() {
    const {t} = this.props;
    const tr = t('common', {returnObjects: true}).sidemenu;
    return (
      <div className='SideMenu'>
        <Sidebar
          as={Menu}
          animation='push'
          direction='right'
          icon='labeled'
          inverted
          vertical
          visible={this.props.isOpen}
          width='wide'
        >
          <Menu.Item>
            <div className='hamburger-div'>
              {this.props.humburguer}
            </div>
          </Menu.Item>

          {this.state.isAuth ? this.protectedMenu(tr) : this.publicMenu(tr)}

          <Divider style={{border: 'none'}}/>
          <Menu.Item as='a' className="menu-option-small">
            <a href="mailto:WUGlobalMysteryShopping@westernunion.com">Get Support</a>
          </Menu.Item>
          <Menu.Item as='a' className="menu-option-small">
            {tr.terms}
          </Menu.Item>
          <Menu.Item as='a' className="menu-option-small">
            {tr.privacy}
          </Menu.Item>
          <Menu.Item as='a' className="menu-option-small">
            <button className="ot-sdk-show-settings">Cookie Settings</button>
          </Menu.Item>
          <Divider style={{border: 'none'}}/>
          <Menu.Item className="copyright">
            <small>{tr.copy}</small>
          </Menu.Item>
        </Sidebar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.sharedData.language,
  };
};

export default connect(mapStateToProps)(withTranslation()(withOktaAuth(SideMenu)));
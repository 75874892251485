import { getConfigData } from '../middleware/index';
import {getWorkflow} from "../util/workflow";

export class UpdateAgentLocation {
  _agentData = {};
  _headerConfig = {
    headers: {},
    mode: {mode: "no-cors"}
  };

  constructor(agentData) {
    console.log(
      "Inside UpdateAgentLocation constructor, agentData = " +
        JSON.stringify(agentData)
    );
    this._agentData = agentData;
  }

  method() {
    return "POST";
  }

  body() {
    return this._agentData;
  }

  host() {
    return getConfigData()[getConfigData().env].awsHost;
  }

  context() {
    return getConfigData()[getConfigData().env].awsContext;
  }

  path() {
    return getConfigData()[getConfigData().env].awsPath;
  }

  getHeader() {
    let token = "";
    if (
      localStorage.getItem("okta-token-storage") &&
      JSON.parse(localStorage.getItem("okta-token-storage")).accessToken
    ) {
      token = JSON.parse(
        localStorage.getItem("okta-token-storage")
      ).accessToken.accessToken;
    }
    this._headerConfig.headers.authorizationToken = "Bearer " + token + " ; flow " + getWorkflow() + " ; brand wu";
    this._headerConfig.headers['Access-Control-Allow-Origin'] = '*';
    this._headerConfig.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';

    return this._headerConfig;
  }

  getPostURL() {
    let url;
    if (this.context() === null || this.context() === "")
      url = getConfigData()[getConfigData().env].awsProtocol + "://" + this.host() + "/" + this.path();
    else
      url = getConfigData()[getConfigData().env].awsProtocol + "://" + this.host() + "/" + this.context() + "/" + this.path();
    return url;
  }
}

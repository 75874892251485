import React from "react";
import {getCurrentBrand} from "../../util/brandselector";
import vigoLogo from "../../assets/images/VigoLogo_NEW_SM.png";
import orlandiLogo from "../../assets/images/orlandi_logo.png";
import "./BrandSelector.scss";
import SvgWrapper from "../shared/SvgWrapper";

const BrandLogo = ({onClick}) => {
  const current = getCurrentBrand();

  const handleClick = (evt) => {
    evt.preventDefault();
    if (typeof onClick === "function") {
      onClick();
    }
  }

  switch (current) {
    case 'vi':
      return (<div className="brand-logo" onClick={handleClick}><img src={vigoLogo} alt='Vigo logo' /></div>);
    case 'ol':
      return (<div className="brand-logo" onClick={handleClick}><img src={orlandiLogo} alt='Orlandi Valuta logo' /></div>);
    default:
      // return (<div className="brand-logo" onClick={handleClick}>{WuLogo}</div>);
      return (<div className="brand-logo" onClick={handleClick}><SvgWrapper src='/static/logo.svg' alt='Western Union logo' /></div>);
  }
}

export {BrandLogo};
import React, {useContext, useEffect, useReducer, useState} from "react";
import {Dropdown} from "semantic-ui-react";

import './UpdateHours.scss';

import Button from "../shared/Button";
import SwitchButton from "../shared/SwitchButton";
import {useTranslation} from "react-i18next";
import uniqid from "uniqid";
import {MainFeedbackContext} from "../LocationFeedback/LocationFeedback";
import FeedbackReducer from "../../reducers/FeedbackReducer";
import {useStore} from "react-redux";


const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const hours = ['12:00', '12:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30',
  '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30'];
const hourType = ["AM", "PM"];

const getHoursOptions = (day) => {
  const mapped = hourType.map((type) => {
    return hours.map((hh) => {
      return {
        key: day + "_" + hh + "_" + type,
        text: hh + " " + type,
        value: hh + "_" + type
      };
    });
  });
  return [...mapped[0], ...mapped[1]];
}

const getDayByName = (name, state) => {
  if (state === null || !state.day || (typeof state.day[Symbol.iterator] !== 'function')) {
    return {name: name, open: "", close: "", isClosed: true};
  }
  for (const item of state.day) {
    if (item.name === name) {
      return item;
    }
  }
}

const RenderDay = ({day, handleUpdate, isFeedback}) => {
  const store = useStore();
  const {review} = useContext(MainFeedbackContext) || {};
  const [storeReview] = useReducer(FeedbackReducer, store.getState().feedback);
  const {t} = useTranslation();
  const tr = t('common', {returnObjects: true});

  const initState = (field) => {
    const operating = (isFeedback) ? review.operatingHours : storeReview.operatingHours;
    const thisDay = getDayByName(day, operating);
    switch (field) {
      case "isopen":
        return (!thisDay.isClosed);
      case "fromhour":
        return (thisDay.open);
      case "tohour":
        return (thisDay.close);
      default:
        return "";
    }
  }

  const [isOpen, setIsOpen] = useState(initState("isopen"));
  const [fromHour, setFromHour] = useState(initState("fromhour"));
  const [toHour, setToHour] = useState(initState("tohour"));

  useEffect(() => {
    const operating = (isFeedback) ? review.operatingHours : storeReview.operatingHours;
    const thisDay = getDayByName(day, operating);
    const open = (!thisDay.isClosed);
    setFromHour(thisDay.open);
    setToHour(thisDay.close);
    setIsOpen(open);
  }, [day, review, setFromHour, setToHour, setIsOpen, isFeedback, storeReview]);

  return (
    <React.Fragment>
      <div key={day} className='day-row'>
        <div className="day-title">
          <span>{tr.days[day]}</span> <SwitchButton id={day} cta_left={tr.close} cta_right={tr.open}
                                                    defaultValue={isOpen ? tr.open : tr.close}
                                                    toggleFunction={() => {
                                                      setIsOpen(!isOpen);
                                                      handleUpdate(day, "isClosed", isOpen);
                                                    }}/>
        </div>
        {isOpen
          &&
          <div className='time-range'>
            <Dropdown key={day + "-from"} selection defaultUpward options={getHoursOptions(day)}
                      text={fromHour} className='hours-dropdown'
                      defaultValue={fromHour}
                      onChange={(_e, data) => {
                        const val = data.value.replace("_", " ");
                        setFromHour(val);
                        handleUpdate(day, "open", val);
                      }}/>
            <span className="TO"> {tr.to} </span>
            <Dropdown key={day + "-to"} selection options={getHoursOptions(day)}
                      text={toHour}
                      className='hours-dropdown'
                      defaultValue={toHour}
                      onChange={(_e, data) => {
                        const val = data.value.replace("_", " ");
                        setToHour(val);
                        handleUpdate(day, "close", val);
                      }}/>
          </div>
        }
      </div>
    </React.Fragment>
  );
}

const UpdateHours = ({cta, skipTitle, continueAction, isFeedback}) => {
  const {review, setReview} = useContext(MainFeedbackContext) || {};
  const store = useStore();
  const [storeReview, setStoreReview] = useReducer(FeedbackReducer, store.getState().feedback);
  const {t} = useTranslation();

  const saveCTA = (cta) ? cta : t('common', {returnObjects: true}).save;
  const [operatingHoursState, setOperatingHours] = useState({
    day: [{name: "Sunday", open: "", close: "", isClosed: true},
      {name: "Monday", open: "", close: "", isClosed: true},
      {name: "Tuesday", open: "", close: "", isClosed: true},
      {name: "Wednesday", open: "", close: "", isClosed: true},
      {name: "Thursday", open: "", close: "", isClosed: true},
      {name: "Friday", open: "", close: "", isClosed: true},
      {name: "Saturday", open: "", close: "", isClosed: true}]
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let oper = operatingHoursState;
    const operatingHours = (isFeedback) ? review.operatingHours : storeReview.operatingHours;

    if (typeof operatingHours.day !== "undefined") {
      for (const item of operatingHours.day) {
        for (let newValKey in oper.day) {
          if (oper.day[newValKey]['name'] === item.name) {
            oper.day[newValKey] = item;
            break;
          }
        }
      }
      setOperatingHours(oper);
    }
  }, [setOperatingHours, review, operatingHoursState, isFeedback, storeReview]);

  const isOpen = (arg) => {
    const day = getDayByName(arg, operatingHoursState);
    return !day.isClosed;
  }

  const fromHours = (arg) => {
    const day = getDayByName(arg, operatingHoursState);
    return day.open;
  }

  const toHours = (arg) => {
    const day = getDayByName(arg, operatingHoursState);
    return day.close;
  }

  const updateDay = (day, field, value) => {
    const newReview = (isFeedback) ? review : storeReview;
    const newVal = operatingHoursState;
    for (let newValKey in newVal.day) {
      if (newVal.day[newValKey]['name'] === day) {
        newVal.day[newValKey][field] = value;
        break;
      }
    }
    setOperatingHours(newVal);
    if (isFeedback) {
      review.operatingHours = newVal;
      setReview(newReview);
    } else {
      storeReview.operatingHours = newVal;
      setStoreReview(newReview);
    }
  }

  const saveAndContinue = () => {
    const newReview = (isFeedback) ? review : storeReview;
    newReview.operatingHours = operatingHoursState;
    if (isFeedback) {
      setReview(newReview);
    } else {
      setStoreReview(newReview);
    }
    if (continueAction && (typeof continueAction === "function")) {
      continueAction();
    } else {
      // Needs to find a better approach
      window.location.hash = "step3";
    }
  }

  return (
    <div>
      <div className='update-hours'>
        {!skipTitle &&
          <div>
            <h1 className="title">Update Service Hours</h1>
            <p className="subtitle">Look for signs posting Western Union service hours or ask someone working at this
              location. If the store is closed please edit accordingly.</p>
          </div>
        }
        {days.map((day) => {
          return (<RenderDay key={uniqid()} day={day} isOpenDay={isOpen} fromHours={fromHours} toHours={toHours}
                             handleUpdate={updateDay} isFeedback={isFeedback} />)
        })}
      </div>
      <div className='action-box'>
        <Button className="save-hours" cta={saveCTA} button_type="primary" handleClick={saveAndContinue}/>
      </div>
    </div>
  );
}

export default UpdateHours;

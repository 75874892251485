import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';
import {initReactI18next} from "react-i18next";
import { getConfigData } from '../middleware/index';

const contentConfig = getConfigData()[getConfigData().env].content;
const _loadPath = contentConfig.protocol + "://" + contentConfig.host + "/" + contentConfig.context + "/{{lng}}/{{ns}}.json";
console.log("i18next _loadPath", _loadPath);
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {
      loadPath: _loadPath,
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    preload: contentConfig.preloadLang,
    react: {
      wait: true,
      useSuspense: true
    },
    reloadInterval: false,
  }, (err, t) => {
    if (err) return console.error("i18n err: " + err)
    console.log(t('common', {returnObjects: true}).open);
    console.log(t('common', { lng: 'es', returnObjects: true}).open);
  });

export default i18n;

import React, {Component} from "react";
import {Slide} from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css'
import "./HowItWorks.scss";
import Button from "../shared/Button";
import {Image, Modal} from "semantic-ui-react";
import image_search from '../../assets/images/illustration-search.svg';
import image_snap from '../../assets/images/illustration-snap.svg';
import image_select from '../../assets/images/illustration-select.svg';

class HowItWorks extends Component {
  constructor(props) {
    super(props);

    this.slides = [
      {
        image: image_search,
        title: 'Search for Locations',
        text: 'Start by using our search bar to locate a nearby Western Union location you would like to visit.'
      },
      {
        image: image_select,
        title: 'Start your visit',
        text: 'Once you’ve selected a location, let us know if there is a discrepancy with the posted business hours. If everything looks correct, select the type of transaction (or inquiry) you’d like to make.'
      },
      {
        image: image_snap,
        title: 'Snap some photos',
        text: 'Capture a photo of the exterior of the Agent location. Feel free to take additional photos of any noteworthy aspects of the location, such as the location of any kiosks.'
      },
    ];

    this.state = {
      started: false,
      isLastSlide: false,
      setShowUsage: props.setShowUsage,
      slideCount: this.slides.length,
      currentSlide: 0
    }
    this.slideRef = React.createRef();

    this.slideProps = {
      responsive: true,
      autoplay: false,
      arrows: false,
      indicators: true,
      infinite: true,
      onStartChange: this.onChangeSlide,
      cssClass: 'basic'
    }

  }

  onStartClick = () => {
    this.setState({started: true});
  }

  onChangeSlide = (from, to) => {
    console.log("onChangeSlide", from, to);
    let isLast = !(to < (this.state.slideCount - 1));
    this.setState({isLastSlide: isLast, currentSlide: to});
  }

  handleNext = () => {
    this.slideRef.current.goNext();
  }

  goHome = () => {
    if (typeof this.state.setShowUsage === "function") {
      this.state.setShowUsage(false);
    } else {
      window.location.pathname = '/';
    }
  }

  render() {
    const skipClass = (this.state.started) ? 'skip' : 'skip first';

    return (
      <div className='how-it-works-container'>
        <Modal
          id="how-it-works"
          key="how-it-works"
          basic
          dimmer={{ className: 'basic' }}
          centered={true}
          open={true}
          size='fullscreen'
        >
          <Modal.Content>
            <div className={this.state.started ? "Onboarding sliding" : "Onboarding"}>
              {this.state.started && <button className={skipClass} onClick={this.goHome}>Skip</button>}
              {!this.state.started &&
                <div className='presentation'>
                  <div className="container">
                    <Image src='static/onboarding01.png' className="onboarding-image"/>

                    <h1 className='title'>Welcome to<br/>Western Union&reg; Experience!</h1>
                    <p className='subtitle'>Thank you for helping us improve your Western Union money transfer
                      experience.
                      Follow these simple step-by-step instructions on how to provide feedback on your money transfer
                      experience.</p>
                  </div>
                  <div className="actions">
                    <Button handleClick={this.onStartClick} cta="Start the tutorial" button_type="primary"
                            isDisabled={false}/>
                    {!this.state.started && <button className={skipClass} onClick={this.goHome}>Skip</button>}
                  </div>
                </div>
              }
              {this.state.started &&
                <div className='slides'>
                  <Slide ref={this.slideRef} {...this.slideProps}>
                    {this.slides.map((slideImage, index) => (
                      <div key={index}>
                        <Image src={slideImage.image} className="illustration"/>
                        <h1 className="title">{slideImage.title}</h1>
                        <p className="subtitle">
                          {slideImage.text}
                        </p>
                        <div className="autoplay-buttons">
                          {this.state.isLastSlide
                            ? <Button handleClick={this.goHome} cta="Ok, got it!" button_type="primary"
                                      isDisabled={false}/>
                            : <Button handleClick={this.handleNext} cta="Next" button_type="primary"
                                      isDisabled={false}/>
                          }
                        </div>
                      </div>
                    ))}
                  </Slide>
                </div>
              }
            </div>
          </Modal.Content>
        </Modal>

        <svg width="375" height="316" viewBox="0 0 375 316" fill="none" xmlns="http://www.w3.org/2000/svg">
          <clipPath id="clipping">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M188.753 316L844 316L640.299 -37.7972C622.771 -68.2409 590.344 -87 555.247 -87L-100 -87L103.701 266.797C121.229 297.241 153.656 316 188.753 316Z"
                  fill="#FEDD00"/>
          </clipPath>
        </svg>


      </div>
    );
  }
}

export default HowItWorks;

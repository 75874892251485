import _ from "lodash";

const getBrandCode = (brand) => {
  switch (brand) {
    case "vi":
      return "vigo";
    case "ol":
      return "ov";
    case "wu":
    default:
      return "wu";
  }
}

const getCurrentBrand = () => {
  let brand = localStorage.getItem("active-brand");
  return (_.isEmpty(brand))
    ? "wu"
    : brand;
}

const setCurrentBrand = (value = 'wu') => {
  localStorage.setItem("active-brand", value);
}

export {getCurrentBrand, setCurrentBrand, getBrandCode};
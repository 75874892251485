import React from "react";

const SearchIcon = ({iconViewbox, iconscale}) => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" viewBox={iconViewbox} xmlns="http://www.w3.org/2000/svg">
    <g transform={iconscale}>
      <path
        d="M3.497 3.41a10.823 10.823 0 0 1 16.157 14.346l3.885 3.893a1.279 1.279 0 1 1-1.812 1.803l-3.884-3.885A10.823 10.823 0 0 1 3.497 3.41zM18.58 6.752a8.558 8.558 0 0 0-15.955 4.337 8.554 8.554 0 0 0 14.565 6.056l.026-.03a.206.206 0 0 1 .034-.026 8.557 8.557 0 0 0 1.33-10.337z"
        fillRule="evenodd"/>
    </g>
  </svg>
  );
}

export default SearchIcon;
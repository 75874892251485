import { getConfigData, getStore } from '../middleware/index';
import {getBrandCode, getCurrentBrand} from "../util/brandselector";
import { v4 as uuidv4 } from 'uuid';

export class FetchAgentLocations {
  _lat = null;
  _lng = null;

  _headerConfig = {
    headers: {}
  };

  constructor(lat, lng) {
    this._lat = lat;
    this._lng = lng;
  }

  method() {
    return "GET";
  }

  host() {
    const config = getConfigData();
    return config[config.env].agents.host;
  }

  getHeader() {
    let activeLang = getStore().getState().sharedData.language;
    if (typeof activeLang === "undefined") {
      activeLang = '*';
    }
    if (getConfigData().env === "uat") {
      this._headerConfig.headers['platform'] = "nextgen";
    }
    this._headerConfig.headers['x-api-key'] = getConfigData()[getConfigData().env].agents.apiKey;
    this._headerConfig.headers['x-wu-externalrefid'] = "onthego";
    this._headerConfig.headers['x-wu-correlationid'] = uuidv4();
    this._headerConfig.headers['Accept-Language'] = activeLang;
    return this._headerConfig;
  }

  context() {
    return getConfigData()[getConfigData().env].agents.context
  }

  queryParameter() {
    const brand = getCurrentBrand();
    const brandCode = getBrandCode(brand);
    let result = "";
    let p_lat = 0;
    let p_lng = 0;

    if (
      this._lat === undefined ||
      this._lat === null ||
      this._lng === undefined ||
      this._lng === null
    ) {
      p_lat = isNaN(getStore().getState().sharedData.lat) ? 0 : getStore().getState().sharedData.lat;
      p_lng = isNaN(getStore().getState().sharedData.lng) ? 0 : getStore().getState().sharedData.lng;

    } else {
      p_lat = isNaN(this._lat) ? -34.60 : this._lat;
      p_lng = isNaN(this._lng) ? -58.53 : this._lng;

    }

    result = (
      "?longitude=" +
      encodeURIComponent(p_lng) +
      "&latitude=" +
      encodeURIComponent(p_lat) +
      "&services=moneyTransferSend,moneyTransferReceive" +
      "&brand=" + brandCode
    );

    return result;
  }
}
//https://locations.westernunion.com/v1/search?q=latitude:34.0641069;longitude:-118.3718785&app_id=6fb15df4&app_key=ab15aa948f6e413f2fb73c2743e6573b&filters=services-_-moneyTransfer
//https://webapi-nonprod.agentlocator.us.westernunion.com/v1/agent/%3Fq%3Dlongitude%3A-75.1652215%3Blatitude%3A39.9525839%26services%3DmoneyTransferSend%2CmoneyTransferReceive
//https://webapi-nonprod.agentlocator.us.westernunion.com/v1/agent/search?q=longitude:-118.243683%3Blatitude:34.052235&agentName=walgreens&openNow=N&services=billpay,stageAndPay,moneyTransferSend,quickCollect

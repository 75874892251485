import _ from "lodash";

const isFirstRun = () => {
  let wfr = localStorage.getItem("wu-first-run");
  return (_.isEmpty(wfr))
    ? true
    : (wfr === true);
}

const setFirstRun = (value = 'false') => {
  localStorage.setItem("wu-first-run", value);
}

export {isFirstRun, setFirstRun};
import { getConfigData } from '../middleware/index';

export class GetQuestions {
  _lang = null;

  _headerConfig = {
    headers: {}
  };

  constructor(lang) {
    this._lang = lang;
  }

  method() {
    return "GET";
  }

  host() {
    return getConfigData()[getConfigData().env].content.host;
  }

  getHeader() {
    this._headerConfig.headers['Cache-Control'] = 'no-cache';
    this._headerConfig.headers['Pragma'] = 'no-cache';
    this._headerConfig.headers['Expires'] = '0';
    return this._headerConfig;
  }

  context() {
    return getConfigData()[getConfigData().env].content.context + "/"
      + this._lang + "/" + getConfigData()[getConfigData().env].content.file.questions;
  }

  queryParameter() {
    return "";
  }
}
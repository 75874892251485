import {call} from "redux-saga/effects";
import APIClient from "../middleware/APIClient";
import {GetQuestions} from "../helpers/GetQuestions";

const _api = new APIClient();

export function *fetchContent(lang = 'en') {
  let getQuestions = new GetQuestions(lang);
  const result = yield call(_api.triggerGetAPI, getQuestions);
  if (
    result.response &&
    result.response.data
  ) {
    return result.response.data;
  }
  return {};
}
import React, {createRef, useContext, useEffect, useRef, useState} from "react";
import "./AddPhotos.scss";
import imgPlaceholder from "../../../assets/images/icon-placeholder.svg";
import Button from "../../shared/Button";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {useTranslation} from "react-i18next";
import uniqid from "uniqid";
import {MainFeedbackContext} from "../LocationFeedback";
import {getPhotosSize, initPhotos, loadImage} from "../../../util/photos";
import {Message} from "semantic-ui-react";

const MAX_PHOTOS_UPLOAD_SIZE = 5242880;

const AddPhotos = ({isFeedback, isEdit, handleCta, nextStep, isBI}) => {
  const {review, setReview} = useContext(MainFeedbackContext);
  const _init = initPhotos(review.photos);
  const [photosSize, setPhotosSize] = useState(getPhotosSize(_init.photos));
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [photos = [], setPhotos] = useState(_init.photos);
  const inputOpenFileRef = createRef();

  const {t} = useTranslation();
  const tr = t('photos', {returnObjects: true});
  const trCommon = t('common', {returnObjects: true});

  const continueCTA = isBI() ? tr.buttons.next_bi : tr.buttons.next;

  const fieldRef = useRef(null);

  useEffect(() => {
    fieldRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {

      /* Get files in array form */
      const files = Array.from(e.target.files);
      let _ff = [];
      files.map(file => {
        if ((file.size + photosSize) <= (MAX_PHOTOS_UPLOAD_SIZE)) {
          _ff.push(file);
          setPhotosSize(file.size + photosSize);
        } else {
          // add filesize error toast
          console.log("file is too big: " + file.name + " - " + file.size);
          const mbsize = ((file.size / 1024)/1024).toFixed(2);
          const mbmax = ((MAX_PHOTOS_UPLOAD_SIZE / 1024)/1024);
          const message = "Max size allowed (combined) is " + mbmax + "Mb.\t\n File '" + file.name + "' ("+mbsize+"Mb)  cannot be uploaded.\n ";
          setError(message);
          setShowError(true);
        }
        return _ff;
      });

      loadImage(_ff, photos, setPhotos);
    }
  }

  const showOpenFileDlg = () => {
    closeError();
    inputOpenFileRef.current.click()
  }

  const saveAndContinue = () => {
    const newReview = review;
    newReview.photos = photos;
    setReview(newReview);
    if (typeof handleCta === 'function') {
      handleCta();
    }
    if (typeof nextStep === 'function') {
      nextStep();
    }
  }

  const closeError = () => {
    setShowError(false);
    setError("");
  }

  const removePhoto = (index) => {
    const _photos = photos;
    setPhotosSize(photosSize - _photos[index].size);
    _photos[index] = {src: imgPlaceholder, pristine: true, size:0};
    setPhotos(_photos);
  }

  return (
    <div className="add-photos" ref={fieldRef}>
      {isFeedback &&
        <p className="instructions">
          {tr.subtitle}
        </p>
      }
      {isFeedback &&
      <div key={uniqid()} >
        <Button cta={tr.buttons.camera} button_type="secondary" handleClick={showOpenFileDlg}/>
        <input ref={inputOpenFileRef} onChange={handleFileChange} type="file" style={{display: "none"}}
                multiple accept="image/png, image/jpeg"/>
      </div>
      }

      {showError &&
        <Message
          onDismiss={() => closeError()}
          error
          header='Error'
          content={error}
        />
      }
      <div className="grid">
        {photos.map((photo, index) => {
          return (
            <div className="Mask" key={uniqid()}  onClick={showOpenFileDlg} >
              <img src={photo.src} className={photo.pristine ? "pristine" : ""}
                   alt="1" />
              {!photo.pristine &&
                <div className="overlay">
                  <CloseOutlinedIcon className="icon" onClick={() => removePhoto(index)}/>
                </div>
              }
            </div>
          )
        })}

      </div>
      {isFeedback &&
        <div className="action-box">
          <Button
            cta={
              isEdit ? trCommon.save : continueCTA
              //continueCTA
            }
            button_type="primary"
            isDisabled={false}
            handleClick={() => {
              saveAndContinue();
            }}/>
        </div>
      }
    </div>
  );
}

export default AddPhotos;

import React, {useState} from "react";
import {Dropdown} from "semantic-ui-react";

import "./BrandSelector.scss";
import {getCurrentBrand, setCurrentBrand} from "../../util/brandselector";
import {getStore} from "../../middleware";
import {loadAgents} from "../../actions/Locations";
import {BrandLogo} from "./BrandLogo";

const BrandSelector = ({hidden, showLogo, clickable, size}) => {
  const [isHidden, setIsHidden] = useState(hidden);

  const brandOptions = [
    {key: 'wuester_union', text: 'Western Union', value: 'wu'},
    {key: 'vigo', text: 'Vigo', value: 'vi'},
    {key: 'orlandi_valuta', text: 'Orlandi Valuta', value: 'ol'}
  ];

  const getClass = () => {
    const brand = getCurrentBrand();
    switch (size) {
      case 'small':
        return "brand-select small " + brand;
      case 'medium':
        return "brand-select medium " + brand;
      case 'large':
      default:
        return "brand-select large " + brand;
    }
  }

  const changeBrand = (evt, {value}) => {
    evt.preventDefault();
    setCurrentBrand(value);
    setIsHidden(hidden);
    getStore().dispatch(loadAgents());
  }

  const buttonClick = () => {
    setIsHidden(!isHidden);
  }

  const handleBlur = (evt) => {
    if (evt) {
      evt.preventDefault();
    }
    setIsHidden(hidden);
  }

  return (
    <>
      {isHidden &&
        <div className={getClass()}>
          <BrandButton handleButtonClick={buttonClick} showLogo={showLogo} isClickable={clickable} />
        </div>
      }
      {!isHidden &&
        <div className={getClass()}>
          <Dropdown
            placeholder="Select Brand"
            fluid
            search
            selection
            defaultValue={getCurrentBrand()}
            options={brandOptions}
            onChange={changeBrand}
            onBlur={handleBlur}
            onClose={handleBlur}
          />
        </div>
      }
    </>
  );
}

const BrandButton = ({isClickable, showLogo, handleButtonClick}) => {
  const  brand = getCurrentBrand();
  const brandNameClass = "brand-name " + brand;
  const changeNameClass = "change-brand " + brand;

  const handleClick = (evt) => {
    if (evt) {
      evt.preventDefault();
    }
    if (isClickable) {
      handleButtonClick();
    }
  }

  const mapBrandName = () => {
    switch (brand) {
      case 'ol':
        return "Orlandi Valuta";

      case 'vi':
        return "Vigo";

      case 'wu':
      default:
        return "Western Union";
    }
  }

  return (
    <>
      {!showLogo &&
        // <button className='btn btn-secondary' onClick={handleClick}>{mapBrandName()}</button>
        <>
          <div className={brandNameClass}>{mapBrandName()}</div>
          {isClickable &&
            <button className={changeNameClass} onClick={handleClick}>Change brand</button>
          }
        </>
      }
      {showLogo &&
        <>
          <BrandLogo className={brandNameClass} onClick={handleClick} />
          {isClickable &&
            <button className={changeNameClass}>Change brand</button>
          }
        </>
      }
    </>
  );
}

export default BrandSelector;

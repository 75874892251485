import EditIcon from "@mui/icons-material/Edit";
import ReactCardFlip from "react-card-flip";
import {Form} from "semantic-ui-react";
import uniqid from "uniqid";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {MainFeedbackContext} from "../../LocationFeedback";
import {getUSStatesSelectOptions} from "../../../../util/Util";
import {useSelector} from "react-redux";
import _ from "lodash";

import "./cards.scss";

const AddressCard = ({locationName}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const {t} = useTranslation();
  const tr = t('review', {returnObjects: true});
  const {review} = useContext(MainFeedbackContext);
  const [address, setAddress] = useState(review.address);
  const addressComponent = useSelector(state => state.sharedData.address_components);
  const options = [{
    "value": "",
    "text": "Select State",
    "key": "void"
  }, ...getUSStatesSelectOptions()];

  const isUSAddress = (!_.isEmpty(addressComponent) && !_.isEmpty(addressComponent.country) && (addressComponent.country === "United States"));

  const updateAddress = (evt, {children}) => {
    evt.preventDefault();
    console.log("review::updateAddress", children);
    setIsFlipped(false);
  }

  const onInputChange = (evt, {id, value}) => {
    evt.preventDefault();
    const newAddr = address;
    newAddr[id] = value;
    setAddress(newAddr);
  }

  return (
    <React.Fragment>
      <div className="section-title">
        <h2>{tr.sections.address}</h2>
        <EditIcon className="edit-icon" onClick={() => setIsFlipped(true)}/>
      </div>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div className={!isFlipped ? 'review-address' : 'card-hidden'}>
          <div className="form-content-wrap">
            <h4>{locationName}</h4>
            <p>
              {address.addrLine1}<br/>
              {typeof address.addrLine2 !== "undefined" && address.addrLine2 && <br/>}
              {address.city}, {address.state}<br/>
              {address.postalCode}<br/>
              Tel: {address.contact}
            </p>
          </div>
        </div>
        <div className={isFlipped ? '' : 'card-hidden'}>
          <Form onSubmit={updateAddress}>
            <Form.Input readOnly={false} fluid label='Address Line 1' placeholder='Address Line 1' id="addrLine1"
                        defaultValue={address.addrLine1} onChange={onInputChange}/>
            <Form.Input readOnly={false} fluid label='Address Line 2' placeholder='Address Line 2' id="addrLine2"
                        defaultValue={address.addrLine2} onChange={onInputChange}/>
            <Form.Input readOnly={false} fluid label='City' placeholder='City' defaultValue={address.city}
                        onChange={onInputChange} id="city"/>
            <Form.Group widths='equal'>
              {(isUSAddress)
                ? (<Form.Select
                  readOnly={false}
                  key={uniqid()}
                  fluid
                  label='State'
                  options={options}
                  placeholder={'State'}
                  defaultValue={address.state}
                  id="state"
                />)
                : (<Form.Input readOnly={false} fluid label='State' placeholder='State' defaultValue={address.state}
                               onChange={onInputChange} id="state"/>)}
              <Form.Input readOnly={false} fluid label='Zip Code' placeholder='Zip Code' id="postalCode"
                          defaultValue={address.postalCode} onChange={onInputChange}/>
            </Form.Group>

            <Form.Input readOnly={false} fluid label='Telephone' placeholder='Telephone' defaultValue={address.contact}
                        onChange={onInputChange} id="contact"/>
            <Form.Button fluid circular type="submit" className="btn" color='yellow' >
              {t('common', {returnObjects: true}).save}
            </Form.Button>
          </Form>
        </div>
      </ReactCardFlip>
    </React.Fragment>
  );
}

export default AddressCard;
import {takeLatest, call, put, fork, take, delay} from "redux-saga/effects";
import { channel } from 'redux-saga'
import {getStore} from '../middleware/index';
import {getSamples} from "./Content";
import _ from "lodash";

const enabledLangs = [
  'en',
  'es'
];

export function* loadQuesitonsSaga() {
  yield takeLatest("LOAD_QUESTIONNARIES", loadQuesitonsWorkerSaga);
}

export function* loadQuesitonsWorkerSaga(_actions) {
  let store = getStore();
  const _in = channel();
  const _out = channel();
  let samples = [];
  yield fork(getSamples, _in, _out);

  try {
    for (let lang of enabledLangs) {
      yield put(_in, lang);
      samples[lang] = yield take(_out);
    }
    store.getState().questions = yield call(parseJSONStatic, samples);

    while (_.isEmpty(store.getState().questions['en']['retail-send-money'])) {
      yield delay(200);
    }
    yield put({type: "LOAD_QUESTIONNARIES_SUCCESS"});
  } catch (e) {
    console.log("Inside loadQuesitonsSaga error = ", e);
  }
}

export function parseJSONStatic(samples) {
  let result = {};
  for (let lang of enabledLangs) {
    let _keys = Object.keys(samples[lang]);
    result[lang] = parseJSONStaticNode(samples[lang]);
    if (_keys[0].toString()==="0") {
      for (let item of _keys) {
        result[lang][samples[lang][item].post_name] = parseJSONStaticNode(samples[lang][item]);
      }
    }
  }
  return result;
}

function parseJSONStaticNode(node) {
  let x = new Map();
  let spl;
  let newKey;

  Object.keys(node).map((key) => {
    if (key.startsWith('questions_')) {
      spl = key.split("_");
      newKey = spl.slice(2, spl.length).join("_");
      if (x.has(spl[1])) {
        let y = x.get(spl[1]);
        y.set(newKey.toString(), node[key]);
        x.set(spl[1], y);
      } else {
        let z = new Map();
        z.set("index", spl[1]);
        z.set("value", '');
        z.set("followup_reason", '');
        z.set("followup_text", '');
        z.set("followup_options", '0');
        z.set(newKey.toString(), node[key]);
        x.set(spl[1], z);
      }
    }
    return x;
  });
  return x;
}
import React, {Component} from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import "./Nav.scss";
import {withTranslation} from "react-i18next";

class Nav extends Component {
  renderSteps(stepNumber) {
    const isActive = this.props.currentStep === stepNumber;
    const dotState = isActive ? "bullet" + stepNumber + " active" : "bullet" + stepNumber;
    return (
      <div
        className={dotState}
        key={`step-${stepNumber}`}
      >
        <span
          // onClick={() => this.props.goToStep(stepNumber)}
        >
          <div>&nbsp;</div>
        </span>
      </div>
    );
  }

  renderList() {
    let dots = [];
    for (let i = 1; i <= this.props.totalSteps; i += 1) {
      dots.push(this.renderSteps(i));
    }
    return dots;
  }

  clickBack = (evt) => {
    evt.preventDefault();
    this.props.goToStep(this.props.currentStep - 1);
  }

  render() {
    const clname = "nav step" + this.props.currentStep;
    const {t} = this.props;
    const tr = t('common', {returnObjects: true});
    return (
      <div>
        {((this.props.currentStep > 1) && (this.props.currentStep < this.props.totalSteps)) &&
          //           NOTE: the next element is not a button but a link, that has to perform an action without href,
          //                 attribute required by screen readers.
          <button className="nav-back-link" onClick={this.clickBack}
                  style={{display: 'block', width: '100%', cursor: 'pointer'}}><ChevronLeftIcon/> {tr.back}</button>}
        {!this.props.isBI() && !this.props.isDigital &&
          <div className={clname}>
            {this.renderList()}
          </div>
        }
        <h1>{this.props.stepsNames[this.props.currentStep - 1]}</h1>
      </div>
    );
  }
}

export default withTranslation()(Nav);

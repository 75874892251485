import React, {useMemo, useState} from "react";
import './Boolean.scss';
import {Label, Message, Radio} from "semantic-ui-react";
import {parseFollowupConditions, parseFollowupReasons, parseOptions, renderFollowup} from "../FieldsUtils";
import _ from "lodash";
import isBoolean from 'validator/lib/isBoolean';

const Boolean = ({settings, handleChange, order, index, followupValue, defaultValue = false}) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState("");
  const [followupValues, setFollowupValues] = useState({radio: followupValue.radio, text: followupValue.text});
  const label = settings.get('question_text');
  const subtitle = settings.get('question_subtitle');
  const elName = 'booleanQuestion_' + order;
  const options = useMemo(() => parseOptions(settings, 'boolean'), [settings]);
  const followup_conditions = useMemo(() => parseFollowupConditions(settings), [settings]);
  const followup_reasons = useMemo(() => parseFollowupReasons(settings), [settings]);

  const handleBooleanChange = (event, data) => {
    event.preventDefault();
    console.log("handleBooleanChange", data);
    const hasError = (!_.isEmpty(data.value) && !isBoolean(data.value, {loose: true}))
      ? "Invalid value"
      : "";
    setError(hasError);
    setValue(data.value);
    handleChange(index, data.value, "", 'input', hasError);
  };

  const handleFollowupChange = (event, newValue, type) => {
    event.preventDefault();
    const _value = followupValues;
    _value[type] = newValue;
    setFollowupValues(_value);
    handleChange(index, value, _value, type);
  }

  return (
    <React.Fragment>
      <div className="questions-boolean">
        <Label>{order}. {label}</Label>
        {subtitle && <p className="input-subtitle">{subtitle}</p>}
        <Radio
          className="questions-boolean-radio"
          key={options[0].key}
          label={options[0].text}
          name={elName}
          value={options[0].value}
          checked={options[0].value === value}
          onChange={handleBooleanChange}
        />
        <br/>
        <Radio
          className="questions-boolean-radio"
          key={options[1].key}
          label={options[1].text}
          name={elName}
          value={options[1].value}
          checked={options[1].value === value}
          onChange={handleBooleanChange}
        />
        {renderFollowup(settings, value, followup_conditions, followup_reasons, followupValues.radio, followupValues.text, order, handleFollowupChange)}
        {(error.length > 0) &&
          <Message
            error
            list={[error]}
          />
        }
      </div>
    </React.Fragment>
  );
}

export default Boolean;

import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import ReactCardFlip from "react-card-flip";
import _ from "lodash";
import uniqid from "uniqid";
import UpdateHours from "../../../UpdateHours";
import {MainFeedbackContext} from "../../LocationFeedback";


const HoursCard = () => {
  const {review} = useContext(MainFeedbackContext);
  const [isFlipped, setIsFlipped] = useState(false);
  const {t} = useTranslation();
  const tr = t('review', {returnObjects: true});

  return (
    <React.Fragment>
      <div className="section-title">
        <h2>{tr.sections.hours}</h2>
        <EditIcon className="edit-icon" onClick={() => setIsFlipped(true)}/>
      </div>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <div className={!isFlipped ? 'review-hours' : 'card-hidden'}>
          <ul>
            {(!_.isEmpty(review.operatingHours)) && Object.entries(review.operatingHours.day).map((day) => {
              return (<li key={uniqid()}>
                <div className="label">{day[1].name} :</div>
                <div className="value">{(day[1].isClosed)
                  ? "Closed"
                  : "Open, " + day[1].open + " - " + day[1].close
                }
                </div>
              </li>);
            })}
          </ul>
        </div>
        <div className={isFlipped ? '' : 'card-hidden'}>
          <UpdateHours operatingHours={review.operatingHours} disabled={!isFlipped}
                       skipTitle continueAction={() => setIsFlipped(false)}/>
        </div>
      </ReactCardFlip>
    </React.Fragment>
  );
}

export default HoursCard;
const FeedbackReducer = (state = {}, action={}) => {
  switch (action.type) {
    case "INIT_REVIEW":
      return {...state, ...action.data};
    case "SAVE_PHOTOS":
      return {...state, photos: action.data.photos}
    case "UPDATE_HOURS":
      return {...state, operatingHours: action.operatingHours}
    case "UPDATE_ADDRESS":
      return {...state, address: action.address}
    case "SAVE_QUESTIONS":
      return {...state, questions: action.questions}
    case "FEEDBACK_SUCCESS":
    default:
      return state;
  }
}

export default FeedbackReducer;
const RootReducer = (state = {}, action={}) => {
  switch (action.type) {
    case "SET_SESSION_ID":
      return { ...state, sessionId: action.sessionId };
    case "SAVE_CURRENT_LOCATION":
      return { ...state, lat: action.data.lat, lng: action.data.lng, formatted_address: action.data.formatted_address, address_components: action.data.address_components };
    case 'SET_LANGUAGE':
      return { ...state, language: action.language };
    default:
      return state;
  }
};
export default RootReducer;
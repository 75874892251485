import React, {createContext, useReducer} from "react";
import {useStore} from "react-redux";
import FeedbackReducer from "../../reducers/FeedbackReducer";
import uniqid from "uniqid";
import Nav from "../LocationFeedback/Nav/Nav";
import StepWizard from "react-step-wizard";
import DigitalQuestionnaires from "./DigitalQuestionnaires/DigitalQuestionnaires";
import {Modal} from "semantic-ui-react";

export const DigitalFeedbackContext = createContext({});

const DigitalFeedback = (props) => {
  const store = useStore();

  const stepsNames = () => {
    let steps = ["Digital Feedback"];
    return steps;
  }

  const [review, setReview] = useReducer(FeedbackReducer, store.getState().feedback);

  const isBI = () => {
    return false;
  }

  const getServiceType = () => {
    return "digital-send-money";
  }

  const closemodal = () => {
    window.location.href = "/";
  }

  return (
    <DigitalFeedbackContext.Provider value={{review, setReview}}>
      <div className="digital-feedback">
        <Modal
          style={{left: "auto !important"}}
          id="review-digital-modal"
          basic
          centered={false}
          closeIcon
          open={true}
          onClose={() => closemodal()}
          size='fullscreen'
        >
          <Modal.Content>
            <StepWizard key={uniqid()} initialStep={1} isHashEnabled={true}
                        nav={<Nav stepsNames={stepsNames()} isBI={isBI} isDigital={true} />}>
              <DigitalQuestionnaires key={uniqid()}
                                     getServiceType={getServiceType}
              />
            </StepWizard>
          </Modal.Content>
        </Modal>
      </div>
    </DigitalFeedbackContext.Provider>
  );
}

export default DigitalFeedback;
import { getStore, getConfigData, getHosts } from '../middleware/index';
import { getSecurity, getBashPath } from "../util/Util";

export class GetAgentLocations {
  _agentLocReq = {};
  _referenceLocation = {};
  _geoCoordinates = {};

  store = getStore();
  _state = this.store.getState();
  _configData = getConfigData();
  _hosts = getHosts();

  _headerConfig = {
    headers: {}
  };

  _lat = null;
  _lng = null;

  constructor(lat, lng) {
    this._lat = lat;
    this._lng = lng;
  }

  method() {
    return "POST";
  }

  body() {
    this._agentLocReq.security = getSecurity();

    if (this._lat !== undefined && this._lat !== null)
      this._geoCoordinates.latitude = this._lat;
    else this._geoCoordinates.latitude = this._state.sharedData.lat;

    if (this._lng !== undefined && this._lng !== null)
      this._geoCoordinates.longitude = this._lng;
    else this._geoCoordinates.longitude = this._state.sharedData.lng;

    this._referenceLocation.geo_coordinates = this._geoCoordinates;
    this._agentLocReq.reference_location = this._referenceLocation;
    this._agentLocReq.search_radius = this._configData.region.countries[
      "US"
    ].FALocationRange;
    this._agentLocReq.max_results = this._configData.region.countries[
      "US"
    ].FAMaxResults;
    this._agentLocReq.sort_by = "DISTANCE";

    this._agentLocReq.bashPath = getBashPath();

    return this._agentLocReq;
  }

  host() {
    const hostName = this._configData.host;
    return this._hosts.hosts[hostName];
  }

  context() {
    return this._configData.context;
  }

  path() {
    return "/GetAgentLocations";
  }

  getHeader() {
    return this._headerConfig;
  }

  getPostURL() {
    return "https://" + this.host() + "/" + this.context() + this.path();
  }
}

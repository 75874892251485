import RootReducer from './RootReducer';
import findAgentReducer from "./findAgentReducer";
import {combineReducers} from "redux";
import FeedbackReducer from "./FeedbackReducer";
import QuestionsReducer from "./QuestionsReducer";

const AppReducers = combineReducers({
  sharedData: RootReducer,
  agentDataMap: findAgentReducer,
  feedback: FeedbackReducer,
  questions: QuestionsReducer,
});

export default AppReducers;
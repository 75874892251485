import React, {useRef, useState} from "react";
import {Checkbox, Label, Message, Radio} from "semantic-ui-react";
import "./Followup.scss";
import {snake_case_string} from "../../../../util/Util";
import _ from "lodash";
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import uniqid from "uniqid";
import {checkNumeric, inputBlur} from "../FieldsUtils";

const Followup = ({reasons, order, text, value_text, value_list, type, placeholder, subtype, handleChange, multipleOptions}) => {
  const [followup_list, setFollowupList] = useState(value_list);
  const [followup_text, setFollowupText] = useState(value_text);
  const [error = "", setError] = useState();
  const isList = (type === "list");
  const isText = (type === "text");
  const isBoth = (type === "both");

  const inputRef = useRef(null);

  const handleChangeText = (event) => {
    event.preventDefault();
    const _subtype = ((typeof subtype !== "undefined") && (subtype === "1")) ? "number" : "text";
    const _value = event.target.value;
    let hasError = "";
    if ((_subtype !== 'number')) {
      hasError = (!_.isEmpty(_value) && !isAlphanumeric(_value, 'en-US', {ignore: " .,;:@_/$&+-()"}))
        ? "Invalid value"
        : "";
      setError(hasError);
    }  else {
      inputBlur(event, setError);
    }
    setFollowupText(_value);
    handleChange(event, _value, 'text', hasError);
  }

  const handleChangeList = (event, data) => {
    event.preventDefault();
    let newVal = data.value;
    if (multipleOptions > 0) {
      let arReason = followup_list.split(",");
      const index = arReason.indexOf(data.value);
      if (index > -1) {
        arReason.splice(index, 1);
      } else {
        arReason.push(data.value);
      }
      newVal = arReason.join(",");
      if (newVal.charAt(0) === ",") {
        newVal = newVal.substring(1);
      }
      setFollowupList(newVal);
    } else {
      setFollowupList(newVal);
    }

    console.log("handleChangeList:",newVal);
    // if (multipleOptions > 0) {
    //   handleChange(event, newVal, 'checkbox', error);
    // } else {
      handleChange(event, newVal, 'radio', error);
    // }
  }

  const renderReasons = () => {
    let list = [];
    const elName = 'booleanQuestionFollowup_' + order;
    reasons.map((reason) => {
      let val = snake_case_string(reason.text);
      if (multipleOptions==="0") {
        list.push(
          <Radio
            className="questions-followup"
            key={uniqid()}
            label={reason.text}
            name={elName}
            value={val}
            checked={val === followup_list}
            onChange={handleChangeList}
          />
        );
      } else {
        const arReason = followup_list.split(",");
        const index = arReason.indexOf(val);
        list.push(
          <Checkbox
            className="questions-followup"
            key={uniqid()}
            label={reason.text}
            name={elName}
            value={val}
            checked={index > -1}
            onChange={handleChangeList}
          />
        );
      }
      return list;
    });
    list.push(
      <> {(error.length > 0) &&
        <Message
          error
          list={[error]}
        />
      }</>
    );
    return list;
  }

  const renderInput = () => {
    const elName = 'textQuestionFollowup_' + order;
    const _subtype = ((typeof subtype !== "undefined") && (subtype === "1")) ? "number" : "text";
    return (
      <div className='followup-input' key={uniqid()}>
        {(_subtype === 'text') &&
          <input type={_subtype} className='input-field-input' value={followup_text} onChange={handleChangeText}
                 placeholder={placeholder}
                 ref={inputRef}
                 autoFocus={inputRef.current === document.activeElement}
                 name={elName} />
        }
        {(_subtype === 'number') &&
          <input type="text" inputMode="decimal" className='input-field-input'
                 value={followup_text} onChange={handleChangeText}
                 min='0.00'
                 pattern="^(?:[\$\(\)%,\.\+-\d]*\d+)$"
                 onKeyDown={checkNumeric}
                 onBlur={(e) => inputBlur(e, setError)}
                 placeholder={placeholder}
                 ref={inputRef}
                 autoFocus={inputRef.current === document.activeElement}
                 name={elName} />
        }
        {(error.length > 0) &&
          <Message
            error
            list={[error]}
          />
        }
      </div>
    );
  }

  const renderBoth = () => {
    return (
      <>
        <>{renderReasons()}</>
        <br/>
        <>{renderInput()}</>
      </>
    );
  }

  return (
    <React.Fragment>
      <div className="followup" key={uniqid()}>
        <Label className="followup-label">{text}</Label>

        {isList && renderReasons()}
        {isText && renderInput()}
        {isBoth && renderBoth()}
      </div>
    </React.Fragment>
  );
}

export default Followup;

import Followup from "./Followup";
import React from "react";
import _ from "lodash";

const getFollowupConditions = (key, data, conditions) => {
  if (key.startsWith("followup_conditions_")) {
    conditions.push(data.get(key));
  }
}

const getFollowupReasons = (key, data, reasons) => {
  if (key.startsWith("followup_reasons_")) {
    let parts = key.split('_');
    let ord = parts[2];
    let r_val = reasons.has(ord) ? reasons.get(ord) : {};
    r_val['key'] = key;
    r_val['name'] = data.get(key);
    reasons.set(ord, r_val);
  }
}

const getOptions = (key, data, parts, ord, option_keys) => {
  let field = parts[parts.length - 1];
  let m_val = option_keys.has(ord) ? option_keys.get(ord) : {};
  m_val['key'] = key;
  m_val[field] = data.get(key);
  option_keys.set(ord, m_val);
  return option_keys;
}

const parseOptions = (settings, type = 'boolean') => {
  let keyStartWith = "question_options_";
  let keyPart = 4;
  switch (type) {
    case "select":
      keyStartWith += "select_";
      keyPart = 3;
      break;
    case "boolean":
    default:
      keyStartWith += "binary_option_";
  }
  let option_keys = new Map();
  for (const key of settings.keys()) {
    if (key.startsWith(keyStartWith)) {
      let parts = key.split('_');
      let ord = parts[keyPart];
      option_keys = getOptions(key, settings, parts, ord, option_keys);
    }
  }
  let options = [];
  option_keys.forEach((value) => {
    options.push({
      key: value.key,
      text: value.name,
      value: value.slug
    })
  });
  return options;
}

const parseFollowupConditions = (settings) => {
  let _followup_conditions = [];
  _followup_conditions.push('any');
  for (const key of settings.keys()) {
    getFollowupConditions(key, settings, _followup_conditions);
  }
  return _followup_conditions;
}

const parseFollowupReasons = (settings) => {
  let followup_reasons = new Map();
  let reasons = [];
  for (const key of settings.keys()) {
    getFollowupReasons(key, settings, followup_reasons);
  }
  followup_reasons.forEach((value) => {
    reasons.push({
      key: value.key,
      text: value.name,
    })
  });
  return reasons;
}

const renderFollowup = (settings, value, conditions, reasons, followupValueList, followupValueText, order, handleChange) => {
  const followup = settings.get('ask_followup');
  const text = settings.get('followup_question');
  const placeholder = settings.get('followup_text_placeholder');
  const subtype = settings.get('is_followup_input_numeric');
  const multipleOptions = settings.get('followup_options');

  if (followup === 'none') {
    return "";
  }
  if (conditions.includes(value)) {
    return (
      <>
        <Followup reasons={reasons}
                  order={order}
                  text={text}
                  value_text={followupValueText}
                  value_list={followupValueList}
                  type={followup}
                  placeholder={placeholder}
                  subtype={subtype}
                  handleChange={handleChange}
                  multipleOptions={multipleOptions}
        />
      </>
    );
  }
}


const inputBlur = (evt, setError) => {
  let validatePattern;
  validatePattern = evt.target.pattern;
  if(evt.target.type === 'textarea') {
    validatePattern = evt.target.getAttribute("data-pattern");
  }
  const regex = new RegExp(validatePattern);
  if (!_.isEmpty(evt.target.value) && (!regex.test(evt.target.value))) {
    evt.preventDefault();
    evt.stopPropagation();
    setError("Invalid value");
  } else {
    setError("");
  }
}

const checkNumeric = (evt) => {
  const { key, keyCode} = evt;
  const nonPtintable = [8,9,13,16,17,18,37,38,39,40,46,91,93];
  const validChars = "0123456789.,()$%&+- ";
  if (nonPtintable.indexOf(keyCode) < 0 && validChars.indexOf(key) < 0) {
    evt.preventDefault();
    evt.stopPropagation();
  }
}

export {
  parseOptions,
  parseFollowupConditions,
  parseFollowupReasons,
  renderFollowup,
  getFollowupConditions,
  getOptions,
  getFollowupReasons,
  inputBlur,
  checkNumeric
};
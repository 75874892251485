import React, {Component} from 'react';
import {connect} from "react-redux";

import './header.scss';
import Hamburger from "hamburger-react";
import SideMenu from "../SideMenu";
import {withOktaAuth} from "@okta/okta-react";
import {withTranslation} from "react-i18next";
import BrandSelector from "../BrandSelector";
import {getCurrentBrand} from "../../util/brandselector";
import SvgWrapper from "../shared/SvgWrapper";

class Header extends Component {
  constructor(props) {
    super(props);
    const lang = (this.props.language) ? this.props.language : "en";
    this.props.i18n.changeLanguage(lang);
    this.state = {
      open: false,
    }
  }

  setOpen = (value) => {
    console.log("setOpen", value);
    this.setState({open: value});
  }

  renderHum = (isOpen, setOpen) => {
    return (
      <Hamburger direction="right"
                 className="hamburger"
                 toggled={isOpen}
                 toggle={setOpen}
                 hideOutline={true}
                 rounded
                 color="var(--primary-yellow)"/>
    );
  }

  render() {
    const isOpen = this.state.open;
    const brandClass = "box d " + getCurrentBrand();
    const clickable = !window.location.pathname.match('location/details');
    const isLogin = window.location.pathname.match('login');
    const headerClass = isLogin
      ? 'header login'
      : 'header';
    return (
      <>
        <div className={headerClass}>
          <a className='box a wulogo' href="/" style={{zIndex: '999'}}>
            <SvgWrapper src='/static/logo.svg' alt='Western Union logo' style={{marginLeft: '10px', width: '60px', height: '34px', marginTop: '16px'}} />
          </a>
          <div className='box b'></div>
          <div className='box c main-menu'>
            {this.renderHum(isOpen, this.setOpen)}
            <SideMenu isOpen={isOpen} setOpen={this.setOpen} humburguer={this.renderHum(isOpen, this.setOpen)}
                      logout={this.props.logout}/>
          </div>
          {!isLogin &&
            <div className={brandClass}>
              <BrandSelector hidden={true} clickable={clickable} />
            </div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.sharedData.language,
  };
};

export default connect(mapStateToProps)(withTranslation()(withOktaAuth(Header)));

import React, {useEffect, useRef, useState} from "react";
import "./AddressAutoComplete.scss";
import CloseIcon from '@mui/icons-material/Close';
import {loadSearchAgents, saveCurrentLocation} from "../../actions/Locations";
import {useDispatch, useSelector} from "react-redux";
import {getUserLocation, getWindowDimensions} from "../../util/Util";
import SearchIcon from "../shared/SearchIcon";
import {useTranslation} from "react-i18next";

let autoComplete;

const handleScriptLoad = (updateQuery, autoCompleteRef, onPlaceLoaded, dispatch) => {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {types: ["geocode"]}
  );

  autoComplete.addListener("place_changed", () =>
    handlePlaceChanged(updateQuery, onPlaceLoaded, dispatch)
  );
}

async function handlePlaceChanged(updateQuery, onPlaceLoaded, dispatch) {
  const addressObject = autoComplete.getPlace();
  if (addressObject) {
    dispatch(saveCurrentLocation(JSON.stringify(addressObject)));
    onPlaceLoaded(JSON.stringify(addressObject));
    updateQuery(addressObject.formatted_address);
    const data = {
      lat: addressObject.geometry.location.lat,
      lng: addressObject.geometry.location.lng,
      formatted_address: addressObject.formatted_address,
      address_components: addressObject.address_components,
    };
    dispatch(loadSearchAgents(JSON.stringify(data)));
  }
}

const AddressAutoComplete = ({initialValue = "", onPlaceLoaded, handleClickTrack, handleClickClear}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(initialValue);
  const autocompleteInput = useRef(null);
  const { t } = useTranslation();


  const lat = useSelector((state) => state.sharedData.lat);
  const lng = useSelector((state) => state.sharedData.lng);
  const formatted_address = useSelector((state) => state.sharedData.formatted_address);
  const address_components = useSelector((state) => state.sharedData.address_components);

  const placeHolders = t('home', {returnObjects: true}).placeHolder;
  const dim = getWindowDimensions();
  const iconViewbox = (dim.width > 380) ? "0 0 24 24" : "0 0 20 20";
  const iconscale = (dim.width > 380) ? "scale(1)" : "scale(0.6)";

  useEffect(
    () => {
      handleScriptLoad(setValue, autocompleteInput, onPlaceLoaded, dispatch);
    },
    [setValue, autocompleteInput, onPlaceLoaded, dispatch]
  );

  const handleFocus = (event) => {
    event.preventDefault();
    event.target.select();
  }

  const handleChange = (event) => {
    event.preventDefault();
    autocompleteInput.current.value = event.target.value;
    handlePlaceChanged(setValue, onPlaceLoaded, dispatch).then();
    setValue(event.target.value);
  }

  const _handleClickTrack = (event) => {
    event.preventDefault();
    getUserLocation().then((response) => {
      autocompleteInput.current.value = response.data.formatted_address;
      handlePlaceChanged(setValue, onPlaceLoaded, dispatch).then();
      setValue(response.data.formatted_address);
      handleClickTrack();
    });
  }

  const _handleClickClear = (event) => {
    event.preventDefault();
    autoComplete.set('place', "");
    const data = {
      lat: lat,
      lng: lng,
      formatted_address: formatted_address,
      address_components: address_components,
    };
    autocompleteInput.current.value = data.formatted_address;
    setValue("");
    handlePlaceChanged(setValue, onPlaceLoaded, dispatch).then();
    handleClickClear();
  }

  return (
    <div className="address-autocomplete-container">
      <div className="address-autocomplete">
        <div className="_search">
          <SearchIcon iconscale={iconscale} iconViewbox={iconViewbox}/>
        </div>
        <input className="_input"
               ref={autocompleteInput}
               id="autocomplete"
               placeholder={placeHolders}
               type="text"
               value={value}
               onFocus={handleFocus}
               onChange={handleChange}
        />
        {(value && value.length > 0) &&
          <div className='Combined-Shape close' onClick={_handleClickClear}>
            <CloseIcon color="#ffdd00"/>
          </div>
        }
        {(!value || value.length < 1) &&
          <div className='Combined-Shape' onClick={_handleClickTrack}>
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4375 8.71875H17.0072C16.8596 4.62398 13.6495 1.29874 9.5625 1.00687V0.5625C9.5625 0.25184 9.31066 0 9 0C8.68934 0 8.4375 0.25184 8.4375 0.5625V1.00687C4.35048 1.29874 1.14044 4.62398 0.992812 8.71875H0.5625C0.25184 8.71875 0 8.97059 0 9.28125C0 9.59191 0.25184 9.84375 0.5625 9.84375H1.02938C1.44404 13.7052 4.56374 16.716 8.4375 16.9931V17.4375C8.4375 17.7482 8.68934 18 9 18C9.31066 18 9.5625 17.7482 9.5625 17.4375V16.9931C13.4363 16.716 16.556 13.7052 16.9706 9.84375H17.4375C17.7482 9.84375 18 9.59191 18 9.28125C18 8.97059 17.7482 8.71875 17.4375 8.71875ZM15.8822 8.71875H14.3437C14.2044 6.0939 12.1768 3.96018 9.5625 3.68719V2.13469C13.0277 2.42235 15.7367 5.2447 15.8822 8.71875ZM12.0937 9.84375H13.1344C12.7735 11.6012 11.3407 12.9381 9.5625 13.1766V12.0937C9.5625 11.7831 9.31066 11.5312 9 11.5312C8.68934 11.5312 8.4375 11.7831 8.4375 12.0937V13.1766C6.65933 12.9381 5.22647 11.6012 4.86562 9.84375H5.90625C6.21691 9.84375 6.46875 9.59191 6.46875 9.28125C6.46875 8.97059 6.21691 8.71875 5.90625 8.71875H4.79531C4.93071 6.72032 6.45266 5.0926 8.4375 4.82344V5.90625C8.4375 6.21691 8.68934 6.46875 9 6.46875C9.31066 6.46875 9.5625 6.21691 9.5625 5.90625V4.82344C11.5529 5.08647 13.0821 6.71573 13.2187 8.71875H12.0937C11.7831 8.71875 11.5312 8.97059 11.5312 9.28125C11.5312 9.59191 11.7831 9.84375 12.0937 9.84375ZM8.4375 2.13469V3.68719C5.82869 3.96662 3.80867 6.09867 3.67031 8.71875H2.11781C2.2633 5.2447 4.97233 2.42235 8.4375 2.13469V2.13469ZM2.16281 9.84375H3.72938C4.11045 12.2252 6.03945 14.0562 8.4375 14.3128V15.8653C5.1849 15.595 2.56673 13.0825 2.16281 9.84375V9.84375ZM9.5625 15.8653V14.3128C11.9606 14.0562 13.8895 12.2252 14.2706 9.84375H15.8372C15.4333 13.0825 12.8151 15.595 9.5625 15.8653Z"/>
            </svg>
          </div>
        }
      </div>
    </div>
  );
}

export default AddressAutoComplete;
